import { React, useState, useEffect } from 'react';
import Header from '../components/Header';
import { Link, useParams, useNavigate } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { API, apiBaseURL } from '../axios';
import swal from 'sweetalert';
import CONSTANT from '../config/constants';
import DOMPurify from 'dompurify';


export default function ContactUs() {
    const navigate = useNavigate();
    const [verified, setVerified] = useState(false);
    const [ddata, setddata] = useState([]);
    const [errMsg, setErrmsg] = useState([]);

    const handleSubmit = async (e) => {
        // alert('A name was submitted: ');
        e.preventDefault();
        err = false;
        try {
            // debugger;
            console.log(ddata);
            console.log(err);
            if (!err) {
                const myheaders = {
                    headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH', 'Content-Type': 'application/json' }
                };
                const { result } = await API.post(`${CONSTANT.API_ROUTE.CONTACT_ADD}`, ddata, myheaders);
                const res = await API.post(`/send-email/contact`, ddata, myheaders);
                const resp1 = await API.post(`/send-email/cthankyou`, ddata, myheaders);
                console.log("test contacts");
                swal("", "Thanks for contacting us, We'll get back to you soon.", "success");
                // window.datalayer.push({
                //     "event": "contact-submitted",
                //     "status": "success"
                // });
                document.querySelector("form").reset();
                // navigate(-1);
            } else {
                alert(err);
                console.log(err);
            }

        }
        catch (error) {
            alert(error);
        }
    }

    const handle = (e) => {
        const newdata = { ...ddata }
        newdata[e.target.id] = e.target.value;

        setddata(newdata)
        console.log(newdata);
    }

    //recaptcha function
    function onChange(value) {
        console.log("Captcha value:", value);
        setVerified(true);
    };

    // Submit the form and send data to api
    var err = true;
    const submitClick = async (e) => {

        e.preventDefault();
        err = false;
        try {
            debugger;
            console.log(ddata);
            console.log(err);
            if (!err) {
                const myheaders = {
                    headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
                };
                const { result } = await API.post(`contact/add/`, ddata, myheaders);
                swal("Yeah", "Query Submitted Successfully", "success");
                document.querySelector("form").reset();
                navigate(-1);
            }

        }
        catch (error) {
            alert(error);
        }
    };

    useEffect(() => {
        // console.log('use effect')
        document.title = 'Amvean - Contacts';
        getMyPostData();
        getContactData();
    }, []);

    const [contactdata, setcontactdata] = useState({});
    const [isError, setisError] = useState("");

    const getMyPostData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            const res = await API.get(`/content/list/1`, myheaders);
            console.log(res.data.message);
            console.log("in the contact");
            setcontactdata(res.data.contentdetails['contact-setting']);

        } catch (error) {
            console.log(error);
            setisError(error.message);
        }
    };

    const mapLink = Object.values(contactdata).filter(m =>
        m.Url === 'map_link'
    );
    const contactNo = Object.values(contactdata).filter(contact =>
        contact.Url === 'contact_page_contact_number'
    );
    const email1 = Object.values(contactdata).filter(email =>
        email.Url === 'contact_page_email1'
    );
    const email2 = Object.values(contactdata).filter(email =>
        email.Url === 'contact_page_email2'
    );

    //for contact jumbo

    const [jumbodata, setjumbodata] = useState([]);
    const [descdata, setdescdata] = useState({});
    const [islistError, setislistError] = useState("");

    const getContactData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // const ddata = { 'DetailType': 'projects' };
            const res = await API.get(`/content/list/6`, myheaders);
            console.log("contact jumbo");
            // const { data } = await axios.post(`details/get-content-details/`, ddata, config)
            setjumbodata(res.data.content);
            setdescdata(res.data.contentdetails.information);
            console.log(descdata);
            console.log(jumbodata);
        } catch (error) {
            console.log(error.message);
            setislistError(error.message);
        }
    };

    const consturlimg = apiBaseURL.baseURL;

    return (
        <>
            <Header logo="white" />
            {/* <!--====== Start Preloader ======--> */}
            {/* <div className="preloader">
                <div className="lds-ellipsis">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> */}
            {/* <!--====== End Preloader ======--> */}
            {/* <!--====== Search From ======--> */}
            <div className="modal fade" id="search-modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="form_group">
                                <input type="text" className="form_control" placeholder="Search here..." />
                                <button className="search_btn"><i className="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!--====== Search From ======--> */}
            {/* <!--====== offcanvas-panel ======--> */}
            <div className="panel-overlay"></div>
            <div className="offcanvas-panel">
                <div className="offcanvas-panel-inner">
                    <div className="panel-logo">
                        <a href="index.html"><img src="assets/images/logo/logo-1.png" alt="Qempo" /></a>
                    </div>
                    <div className="about-us">
                        <h5 className="panel-widget-title">About Us</h5>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="panel-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="fal fa-map-marker-alt"></i>
                                121 King St, Melbourne VIC 3000, Australia.
                            </li>
                            <li>
                                <i className="fal fa-envelope-open"></i>
                                <a href="mailto:hello@barky.com"> hello@barky.com</a>
                                <a href="mailto:info@barky.com">info@barky.com</a>
                            </li>
                            <li>
                                <i className="fal fa-phone"></i>
                                <a href="tel:(312)-895-9800">(312) 895-9800</a>
                                <a href="tel:(312)-895-9888">(312) 895-9888</a>
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="panel-close"><i className="fal fa-times"></i></a>
                </div>
            </div>
            {/* <!--====== offcanvas-panel ======--> */}

            {/* header in components */}

            {/* <!--====== Start Bredcumbs section ======--> */}
            <div className="new-menu-bar" style={{ position: "relative" }}>
                <div className="hero-shape">
                    <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
                    <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
                </div>
                <div className="header-bg-img" style={{ paddingTop: "100px", backgroundColor: "#DBF3F3" }}>
                    {jumbodata.map((jumbotron) =>
                        <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/contact-us.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="page-title-inner">
                                            <ul className="bredcumbs-link">
                                                <li><Link to="/">{jumbotron.JumbotronNavLink1Title}</Link></li>
                                                <li className="active">{jumbotron.JumbotronNavLink2Title}</li>
                                            </ul>
                                            <div className="title">
                                                <h1>{jumbotron.JumbotronNavLink2Title}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
            {/* {jumbodata.map((jumbotron) =>
                // <section className="bredcumbs-area bg_cover" style={{ background: "url(" + consturlimg + jumbotron.JumbotronImage + ")", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/contact-us.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="page-title-inner">
                                    <ul className="bredcumbs-link">
                                        <li><Link to="/">{jumbotron.JumbotronNavLink1Title}</Link></li>
                                        <li className="active">{jumbotron.JumbotronNavLink2Title}</li>
                                    </ul>
                                    <div className="title">
                                        <h1>{jumbotron.JumbotronNavLink2Title}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )} */}
            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/bg-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="page-title-inner">
                                <ul className="bredcumbs-link">
                                    <li><Link to="/">Home</Link></li>
                                    <li><a href="index.html">Home</a></li>
                                    <li className="active">Contact</li>
                                </ul>
                                <div className="title">
                                    <h1>Contact</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Bredcumbs section ======--> */}
            {/* <!--====== Start contact-map-section ======--> */}
            <section className="contact-map-section">
                <div className="map-box">
                    {mapLink.map((link) =>
                        <iframe src={link.Detail}></iframe>
                    )}
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.89329363713!2d-73.98433548505619!3d40.74237344369457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25909cdc4c50b%3A0x880d7f46e54372e1!2s134%20E%2028th%20St%2C%20New%20York%2C%20NY%2010016%2C%20USA!5e0!3m2!1sen!2sin!4v1677585144461!5m2!1sen!2sin"></iframe> */}
                    {/* <iframe src="https://maps.google.com/maps?q=new%20york&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe> */}
                </div>
            </section>
            {/* <!--====== End contact-map-section ======--> */}
            {/* <!--====== Start contact-information-area ======--> */}
            <section className="contact-info-area-v1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="contact-info-item text-center wow fadeInUp mb-40">
                                <div className="info-inner-item">
                                    <div className="icon">
                                        <img src="assets/images/contact/icon-1.png" alt="" />
                                    </div>
                                    <div className="info">
                                        <h4>Phone Number</h4>
                                        {contactNo.map((number) =>
                                            <p><a href={"tel:" + number.Detail}>{number.Detail}</a></p>
                                        )}
                                        {/* <p><a href="tel:1.212.810.2074">1.212.810.2074</a></p> */}
                                        <p><a></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="contact-info-item text-center wow fadeInUp mb-40">
                                <div className="info-inner-item">
                                    <div className="icon">
                                        <img src="assets/images/contact/icon-2.png" alt="" />
                                    </div>
                                    <div className="info">
                                        <h4>Email Address</h4>
                                        {email1.map((email) =>
                                            <p><a href={"mailto:" + email.Detail}>{email.Detail}</a></p>
                                        )}
                                        {email2.map((email) =>
                                            <p><a href={"mailto:" + email.Detail}>{email.Detail}</a></p>
                                        )}
                                        {/* <p><a href="mailto:sales@amvean.com">sales@amvean.com</a></p>
                                        <p><a href="mailto:support@amvean.com">support@amvean.com</a></p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="contact-info-item text-center wow fadeInUp mb-40">
                                <div className="info-inner-item">
                                    <div className="icon">
                                        <img src="assets/images/contact/icon-3.png" alt="" />
                                    </div>
                                    <div className="info">
                                        <h4>FAQ's</h4>
                                        <div>
                                            <Link to="/services"><button style={{ background: "white", color: "#fd6350", marginTop: "30px", fontSize: "18px" }}>
                                                View Faq's <i class="far fa-arrow-right"></i>
                                            </button></Link>
                                            {/* <button style={{ background: "white", color: "#fd6350", marginTop: "30px", fontSize: "18px" }}>
                                                View Faq's <i class="far fa-arrow-right"></i>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== End contact-information-area ======--> */}
            {/* new desc section start */}
            {/* <div className="container" style={{ marginTop: "50px", marginBottom: "50px" }}>
                <div className="row">
                    <div className="col-12">
                        <div className="blog-post-item" id="contact-desc" style={{ paddingBottom: "40px", paddingTop: "50px", paddingLeft: "50px", paddingRight: "50px", border: "2px solid #efefef" }}>
                            {Object.values(descdata).map((desc, index) =>
                                <div className="entry-content" key={index}>
                                    <div className="section-title mb-45" >
                                        <h2>{desc.DetailHeading}</h2>
                                    </div>
                                    <div className="row">
                                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc.Detail) }}></p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container" style={{ marginTop: "50px", marginBottom: "50px" }}>
                <div className="row" style={{ marginBottom: "50px" }}>
                    <div className="col-12" id="new-contact-sec2">
                        <h2 style={{ textAlign: "center", marginBottom: "40px" }}>Contact Us Today !</h2>
                        <p>To learn more about our email hosting and website design services, please contact us today. We would be happy to answer any questions you have and to provide you with a free quote for our services.</p>
                    </div>
                </div>
                <div id="new-contact-sec">
                    <h4>Here are some of the benefits of using our services:</h4>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="contact-title"><h5>Peace of mind</h5></div>
                            <div className="contact-desc">You can rest assured knowing that your email and website are in good hands with our team of experts.</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="contact-title"><h5>Cost-effectiveness</h5></div>
                            <div className="contact-desc">Our services are affordable and competitive.
                                Flexibility: We offer a variety of plans to fit your budget and needs.</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="contact-title"><h5>Flexibility</h5></div>
                            <div className="contact-desc">We offer a variety of plans to fit your budget and needs.
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="contact-title"><h5>Scalability</h5></div>
                            <div className="contact-desc">Our services can grow with your business.</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="contact-title"><h5>Security</h5></div>
                            <div className="contact-desc">We take security seriously and offer a variety of features to protect your data.</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* new desc section end */}
            {/* <!--====== Start Contact section ======--> */}
            <section className="contact-area-v1 pt-80 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title mb-45 wow fadeInUp">
                                <span className="span">Get Pure Estimate</span>
                                <h2>Get In Touch With us</h2>
                            </div>
                            <div className="contact-form-area mb-60 wow fadeInUp">
                                <form method="post" onSubmit={(e) => handleSubmit(e)} >
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form_group">
                                                <input type="text" className="form_control" placeholder="Enter full name" name="name" required id="Name" value={ddata.Name} onChange={(e) => handle(e)} />
                                                <i className="far fa-user"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form_group">
                                                <input type="email" className="form_control" placeholder="Enter email address" name="email" required id="Email" value={ddata.Email} onChange={(e) => handle(e)} />
                                                <i className="far fa-envelope"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form_group">
                                                <select style={{ backgroundColor: "white", border: "none", color: "#777777", borderRadius: "28px", height: "60px", padding: "0 55px", height: "60px", marginBottom: "20px", lineHeight: "60px", boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.08)", width: "100%" }} id="QueryType" value={ddata.QueryType} onChange={(e) => handle(e)}  >
                                                    <option data-display="Select Subject">Select Subject</option>
                                                    <option value="Customer Support">Customer Support</option>
                                                    <option value="Mangement Support">Mangement Support</option>
                                                    <option value="Ticket Problem">Ticket Problem</option>
                                                </select>
                                                <i className="far fa-book"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form_group">
                                                <textarea name="message" placeholder="Enter message" className="form_control" id="Message" value={ddata.Message} onChange={(e) => handle(e)}  ></textarea>
                                                <i className="far fa-pen"></i>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form_group">
                                                <div class="my-captcha">
                                                    <ReCAPTCHA
                                                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                        onChange={onChange}
                                                    />
                                                </div>

                                                <input type="submit" className="btn btn-success" disabled={!verified} style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", borderStyle: "none", padding: "15px 45px 15px 45px", borderRadius: "30px 30px 30px 30px", fontWeight: "700", marginTop: "10px" }} value="Get A Quote" name="submit" onChange={(e) => handle(e)} />


                                                {/* <button type="button" className="btn btn-primary" disabled={!verified} style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", borderStyle: "none", padding: "15px 45px 15px 45px", borderRadius: "30px 30px 30px 30px", fontWeight: "700", marginTop: "10px" }} name="submit" value="Submit" onChange={(e) => handle(e)} >Get A Quote</button> */}
                                                {/* <button className="main-btn" type="submit" >Get A Quote</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-img mb-60 wow fadeInRight">
                                <img src="assets/images/contact/contact-11.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== End Contact section ======--> */}

            {/* footer in components */}

            {/* <!--====== back-to-top ======--> */}
            <a href="#" className="back-to-top" ><i className="fas fa-angle-up"></i></a>
        </>
    )
}
