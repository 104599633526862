import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API, apiBaseURL } from '../axios';
import { FaTwitter } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

export default function Header(params) {
    //for navigation
    const [active, setActive] = useState(false);

    const showMenu = () => {
        active === true ? setActive(false) : setActive(true)
    }

    const [subMenuActiveService, setSubMenuActiveService] = useState(false);

    const showSubMenuService = () => {
        console.log("for service-");
        console.log(subMenuActiveService);
        subMenuActiveService === true ? setSubMenuActiveService(false) : setSubMenuActiveService(true)
    }

    const [subMenuActiveBlog, setSubMenuActiveBlog] = useState(false);

    const showSubMenuBlog = () => {
        console.log("for blog-");
        console.log(subMenuActiveBlog);
        subMenuActiveBlog === true ? setSubMenuActiveBlog(false) : setSubMenuActiveBlog(true)
    }

    //for api

    useEffect(() => {
        getMyPostData();
        getMyBlogData();
        getMyContactData();
    }, []);
    //for service

    const [serviceList, setserviceList] = useState([]);
    const [serviceError, setisError] = useState("");

    //for dropdown
    const [myDropdown, setmyDropdown] = useState(false);


    const getMyPostData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // const mdata = { 'DetailType': 'services' };
            // const { data } = await axios.post(`details/get-content-details/`, mdata, config);
            const res = await API.get(`/content/list/2`, myheaders);
            console.log(res.data.message);
            console.log("in the header");
            setserviceList(res.data.contentdetails.services);
            console.log("service length");
            console.log(serviceList);
            console.log(Object.keys(serviceList).length);
            // if(Object.keys(serviceList).length>2){
            //     setmyDropdown(true);
            // }
            // setserviceList(data.data);

        } catch (error) {
            setisError(error.message);
        }
    };

    useEffect(() => {
        if (Object.keys(serviceList).length > 6) {
            setmyDropdown(true);
        }
    }, [serviceList]);

    const myActiveserviceList = Object.values(serviceList).filter(service =>
        service.Status === 1
    )

    const myservices = myActiveserviceList.map((service) =>
        <li key={service.ContentDetailId}>
            <Link to={"/" + service.DetailSlug}>{service.MainHeading}</Link>
        </li>
    );
    //for blog
    const [blogData, setBlogData] = useState([]);
    const [blogError, setBlogError] = useState("");

    // using asynk and await
    const getMyBlogData = async () => {
        // console.log('get my post data called');
        try {
            // console.log('get my post data called in try');
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // console.log('get my post data called in try now get data');
            const res = await API.get(`/blogs/list`, myheaders);
            // console.log(res.data.data);
            console.log(res.data.message);
            setBlogData(res.data.data.at(0));
            console.log(blogData);
        } catch (error) {
            // console.log('get my post data called in catch');
            console.log(error.message);
            setBlogError(error.message);
        }
    };

    //for contact
    const [contactData, setcontactData] = useState([]);
    const [contactError, setcontactError] = useState("");

    const getMyContactData = async () => {
        // console.log('get my post data called');
        try {
            // console.log('get my post data called in try');
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // console.log('get my post data called in try now get data');
            const res = await API.get(`/content/list/1`, myheaders);
            // console.log(res.data.data);
            console.log(res.data.message);
            setcontactData(res.data.contentdetails['contact-setting']);
            console.log(contactData);
        } catch (error) {
            // console.log('get my post data called in catch');
            console.log(error.message);
            setcontactError(error.message);
        }
    };

    const phoneNo = Object.values(contactData).filter(header =>
        header.Url === 'header_contact_number'
    );

    //for dropdown

    // const [myDropdown, setmyDropdown] = useState(() => {
    //     console.log("length inside usestate");
    //     console.log(Object.keys(serviceList).length);
    //     if (Object.keys(serviceList).length > 3) {
    //         console.log("big");
    //       return true;
    //     }
    //     console.log("small");
    //     return false;
    //   });
    console.log("mydopdown");
    console.log(myDropdown);

    return (
        <>
            {/* <div className="panel-overlay"></div> */}
            {/* header start */}
            <header className="header-area-one transparent-header d-none d-sm-block" id="my-header">
                <div className={`header-navigation ${window.matchMedia('(max-width: 1199px)').matches ? "breakpoint-on" : ""}`} id="my-header-navigation">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-4">
                                <a href="https://twitter.com/" target="_blank"><FaTwitter style={{marginLeft: "15px"}}/></a>
                            </div> */}
                            {/* <div className="col-12 d-flex justify-content-end">
                                <span style={{ paddingRight: "7px" }}><i className="fa fa-phone fa-rotate-90"></i></span>
                                {phoneNo.map((phone) =>
                                    <b>For support contact us on: <a href={"tel:" + phone.Detail}>{phone.Detail}</a></b>
                                )}
                            </div> */}
                        </div>
                        <div className="row align-items-center" style={{ marginTop: "-10px", marginBottom: "-10px" }}>
                            <div className="col-lg-2 col-5" style={{ marginTop: "3px" }}>
                                <div className="site-branding">
                                    <div className="brand-logo">
                                        {/* <Link to="/"><img src={params.logo === "colored" ? "assets/images/logo/amvean-red.png" : "assets/images/logo/amvean-black.png"} className="img-fluid" alt="Qempo" /></Link> */}
                                        <Link to="/"><img src={"assets/images/logo/amvean-logo-red-black.png"} className="img-fluid" alt="Qempo" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-2">
                                <div className={`nav-menu ${active === true ? "menu-on" : ""}`}>
                                    {/* <!-- Navbar Close Icon --> */}
                                    <div className="navbar-close" onClick={() => showMenu()}><i className="fal fa-times"></i></div>
                                    {/* <!-- Nav Menu --> */}
                                    <nav className="main-menu">
                                        <ul>
                                            <li className="menu-item">
                                                <Link to="/" style={{ fontSize: "17px" }}>Home</Link>
                                            </li>
                                            {/* <li className="menu-item"> */}
                                            <li className={`menu-item menu-item-has-children ${subMenuActiveService === true ? "active" : ""}`}>
                                                {/* <li className="menu-item menu-item-has-children"> */}
                                                <Link to="/services" style={{ fontSize: "17px" }}>Services</Link>
                                                {/* <div style={{display: "flex"}}>
                                                    <ul className="sub-menu" style={{display: "grid", gridTemplateColumns: "repeat(auto-fill,minmax(160px, 1fr))"}}>{myservices}</ul>
                                                </div> */}
                                                <ul id={myDropdown ? "serviceid" : ""} className="sub-menu">
                                                    {/* <li><a href="service-1.html">Service One</a></li>
                                                    <li><a href="service-2.html">Service Two</a></li> */}
                                                    {myservices}
                                                    {/* <li>
                                                        <Link to="/">Managed IT Services</Link>
                                                    </li>}
                                                    {/* <li>
                                                        <Link to="/service-details-it">Managed IT Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-cyber-security">Cyber Security Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-office-cabling">Structured Office Cabling</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-network-data">Network / Data Center Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-email-web">Corporate Email & Web Design Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-cloud">Cloud Support Solutions</Link>
                                                    </li> */}
                                                </ul>
                                                <span class="dd-trigger" onClick={() => showSubMenuService()}><i class="fas fa-angle-down"></i></span>
                                            </li>
                                            <li className="menu-item"><Link to="/projects" style={{ fontSize: "17px" }}>Projects</Link></li>
                                            {/* <li className={`menu-item menu-item-has-children ${subMenuActiveBlog === true ? "active" : ""}`}>
                                                <Link to="/blog-list" style={{fontSize: "16px"}}>Blog</Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to={"/blog-details-" + blogData.BlogSlug}>Blog Details</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/blog-list">Blog List</Link>
                                                    </li>
                                                </ul>
                                                <span class="dd-trigger" onClick={() => showSubMenuBlog()}><i class="fas fa-angle-down"></i></span>
                                            </li> */}
                                            <li className="menu-item"><Link to="/blog" style={{ fontSize: "17px" }}>Blog</Link></li>
                                            <li className="menu-item"><Link to="/about" style={{ fontSize: "17px" }}>About</Link></li>
                                            <li className="menu-item"><Link to="/contact-us" style={{ fontSize: "17px" }}> Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-3 col-5">
                                {/* Header Right Nav */}
                                <div className="header-right-nav">
                                    <div className="menu-icon-group">
                                        <ul>
                                            {/* <li>
                                                <span style={{ paddingRight: "7px" }}><i className="fa fa-phone fa-rotate-90"></i></span>
                                                {phoneNo.map((phone) =>
                                                    <b>Contact us: <a href={"tel:" + phone.Detail}>{phone.Detail}</a></b>
                                                )}
                                                <a href="https://twitter.com/Amvean" target="_blank"><FaTwitter style={{ marginLeft: "10px" }} /></a>
                                            </li> */}
                                            <li><a href="https://twitter.com/Amvean" className="icon" target="_blank"><FaTwitter style={{fontSize: "13px"}}/></a></li>
                                            <li><a href="mailto:sales@amvean.com" data-title='sales@amvean.com' className="icon" target="_blank"><FaEnvelope style={{fontSize: "13px"}}/></a></li>
                                            <li>
                                                {phoneNo.map((phone) =>
                                                    <a href={"tel:" + phone.Detail} className="icon1" data-title={phone.Detail} style={{color: "#fd6350", fontWeight: "bold"}} > {phone.Detail} </a>
                                                )}
                                            </li>
                                            {/* <li className="search-nav"><a href="#" data-bs-toggle="modal" data-bs-target="#search-modal" className="icon search-btn"><i className="far fa-search"></i></a></li> */}
                                            {/* <li><a href="#" className="icon cart-btn"><i className="fal fa-shopping-cart"></i></a></li>
                                            <li className="off-nav"><a href="#" className="icon off-menu"><i className="fal fa-bars"></i></a></li> */}
                                            {/* <li><div className="navbar-toggler" onClick={() => showMenu()}>
                                                <span></span><span></span><span></span>
                                            </div></li> */}
                                            <li><div className={`navbar-toggler ${active === true ? "active" : ""}`} onClick={() => showMenu()}>
                                                <span></span><span></span><span></span>
                                            </div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </header>
            <header className="header-area-one transparent-header d-block d-sm-none" id="my-header">
                <div className={`header-navigation ${window.matchMedia('(max-width: 1199px)').matches ? "breakpoint-on" : ""}`} id="my-header-navigation">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-4">
                                <a href="https://twitter.com/" target="_blank"><FaTwitter style={{marginLeft: "15px"}}/></a>
                            </div> */}
                            {/* <div className="col-12 d-flex justify-content-end">
                                <span style={{ paddingRight: "7px" }}><i className="fa fa-phone fa-rotate-90"></i></span>
                                {phoneNo.map((phone) =>
                                    <b>For support contact us on: <a href={"tel:" + phone.Detail}>{phone.Detail}</a></b>
                                )}
                            </div> */}
                        </div>
                        
                        <div className="row align-items-center " style={{ marginTop: "-10px", marginBottom: "-10px" }}>
                            <div className="col-lg-2 col-5" style={{ marginTop: "3px" }}>
                                <div className="site-branding">
                                    <div className="brand-logo">
                                        {/* <Link to="/"><img src={params.logo === "colored" ? "assets/images/logo/amvean-red.png" : "assets/images/logo/amvean-black.png"} className="img-fluid" alt="Qempo" /></Link> */}
                                        <Link to="/"><img src={"assets/images/logo/amvean-logo-red-black.png"} className="img-fluid" alt="Qempo" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-2 offset-5">
                                  <div className={`navbar-toggler ${active === true ? "active" : ""}`} onClick={() => showMenu()}>
                                                <span></span><span></span><span></span>
                                            </div>
                                <div className={`nav-menu ${active === true ? "menu-on" : ""}`}>
                                    {/* <!-- Navbar Close Icon --> */}
                                    <div className="navbar-close" onClick={() => showMenu()}><i className="fal fa-times"></i></div>
                                    {/* <!-- Nav Menu --> */}
                                    <nav className="main-menu">
                                        <ul>
                                            <li className="menu-item">
                                                <Link to="/" style={{ fontSize: "17px" }}>Home</Link>
                                            </li>
                                            {/* <li className="menu-item"> */}
                                            <li className={`menu-item menu-item-has-children ${subMenuActiveService === true ? "active" : ""}`}>
                                                {/* <li className="menu-item menu-item-has-children"> */}
                                                <Link to="/services" style={{ fontSize: "17px" }}>Services</Link>
                                                {/* <div style={{display: "flex"}}>
                                                    <ul className="sub-menu" style={{display: "grid", gridTemplateColumns: "repeat(auto-fill,minmax(160px, 1fr))"}}>{myservices}</ul>
                                                </div> */}
                                                <ul id={myDropdown ? "serviceid" : ""} className="sub-menu">
                                                    {/* <li><a href="service-1.html">Service One</a></li>
                                                    <li><a href="service-2.html">Service Two</a></li> */}
                                                    {myservices}
                                                    {/* <li>
                                                        <Link to="/">Managed IT Services</Link>
                                                    </li>}
                                                    {/* <li>
                                                        <Link to="/service-details-it">Managed IT Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-cyber-security">Cyber Security Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-office-cabling">Structured Office Cabling</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-network-data">Network / Data Center Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-email-web">Corporate Email & Web Design Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service-details-cloud">Cloud Support Solutions</Link>
                                                    </li> */}
                                                </ul>
                                                <span class="dd-trigger" onClick={() => showSubMenuService()}><i class="fas fa-angle-down"></i></span>
                                            </li>
                                            <li className="menu-item"><Link to="/projects" style={{ fontSize: "17px" }}>Projects</Link></li>
                                            {/* <li className={`menu-item menu-item-has-children ${subMenuActiveBlog === true ? "active" : ""}`}>
                                                <Link to="/blog-list" style={{fontSize: "16px"}}>Blog</Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to={"/blog-details-" + blogData.BlogSlug}>Blog Details</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/blog-list">Blog List</Link>
                                                    </li>
                                                </ul>
                                                <span class="dd-trigger" onClick={() => showSubMenuBlog()}><i class="fas fa-angle-down"></i></span>
                                            </li> */}
                                            <li className="menu-item"><Link to="/blog" style={{ fontSize: "17px" }}>Blog</Link></li>
                                            <li className="menu-item"><Link to="/about" style={{ fontSize: "17px" }}>About</Link></li>
                                            <li className="menu-item"><Link to="/contact-us" style={{ fontSize: "17px" }}> Contact</Link></li>
                                        </ul>
                                    </nav>
                                  
                                </div>
                            </div>
                            <div className="col-lg-3 col-12">
                                {/* Header Right Nav */}
                                <div className="header-right-nav">
                                    <div className="menu-icon-group">
                                        <ul>
                                            {/* <li>
                                                <span style={{ paddingRight: "7px" }}><i className="fa fa-phone fa-rotate-90"></i></span>
                                                {phoneNo.map((phone) =>
                                                    <b>Contact us: <a href={"tel:" + phone.Detail}>{phone.Detail}</a></b>
                                                )}
                                                <a href="https://twitter.com/Amvean" target="_blank"><FaTwitter style={{ marginLeft: "10px" }} /></a>
                                            </li> */}
                                            <li><a href="https://twitter.com/Amvean" className="icon" target="_blank"><FaTwitter style={{fontSize: "13px"}}/></a></li>
                                            <li><a href="mailto:sales@amvean.com" data-title='sales@amvean.com' className="icon" target="_blank"><FaEnvelope style={{fontSize: "13px"}}/></a></li>
                                            <li>
                                                {phoneNo.map((phone) =>
                                                    <a href={"tel:" + phone.Detail} className="icon1" data-title={phone.Detail} style={{color: "#fd6350", fontWeight: "bold"}} > {phone.Detail} </a>
                                                )}
                                            </li>
                                            {/* <li className="search-nav"><a href="#" data-bs-toggle="modal" data-bs-target="#search-modal" className="icon search-btn"><i className="far fa-search"></i></a></li> */}
                                            {/* <li><a href="#" className="icon cart-btn"><i className="fal fa-shopping-cart"></i></a></li>
                                            <li className="off-nav"><a href="#" className="icon off-menu"><i className="fal fa-bars"></i></a></li> */}
                                            {/* <li><div className="navbar-toggler" onClick={() => showMenu()}>
                                                <span></span><span></span><span></span>
                                            </div></li> */}
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* header end */}
        </>
    )
}
