import { React, useState, useEffect, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from '../components/Header';
import { API, apiBaseURL } from '../axios';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Blog from './Blog';

export default function BlogList() {

    const [myData, setmyData] = useState([]);
    const [isError, setisError] = useState("");
    // calling the function
    useEffect(() => {
        // console.log('use effect')
        document.title = 'Amvean - Blogs';
        getMyPostData();
    }, []);

    // using asynk and await
    const getMyPostData = async () => {
        // console.log('get my post data called');
        try {
            // console.log('get my post data called in try');
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // console.log('get my post data called in try now get data');
            const res = await API.get(`/blogs/list`, myheaders);
            // console.log(res.data.data);
            console.log(res.data.message);
            setmyData(res.data.data);
            console.log(myData);
            console.log(myData.length);
        } catch (error) {
            // console.log('get my post data called in catch');
            console.log(error.message);
            setisError(error.message);
        }
    };

    const consturlimg = apiBaseURL.baseURL;

    const myActiveData = myData.filter(blog =>
        blog.Status === 1
    )

    // const myblog = myData.map((blog) =>
    //     <div className="col-lg-6" key={blog.BlogId} style={{ paddingLeft: "20px", paddingRight: "20px" }}>
    //         <div className="blog-post-item mb-20 wow fadeInUp">
    //             <div className="post-thumbnail">
    //                 <img src={consturlimg + blog.BlogImage} alt={blog.BlogImage} />
    //             </div>
    //             <div className="entry-content">
    //                 <Link to={"/blog-details-" + blog.BlogId}><h3 className="title">{blog.BlogTitle}</h3></Link>
    //                 {/* <h3 className="title"><a href="blog-details.html"></a>{blog.BlogTitle}</h3> */}
    //                 {/* <p className='cutoff-txt' ref={blogdesc}></p> */}
    //                 <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.BlogSummary) }}></p>
    //                 {/* <p dangerouslySetInnerHTML={{ __html: blog.BlogDescription }}></p> */}
    //                 {/* <p className='cutoff-txt'>{blog.BlogDescription}</p> */}
    //                 <div>
    //                     <button style={{ background: "white", color: "#fd6350", marginTop: "30px", marginBottom: "30px", fontSize: "20px" }}><Link to={"/blog-details-" + blog.BlogId}>Continue Reading <i class="far fa-arrow-right"></i></Link>
    //                     </button>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <>
            <Header logo="white" />

            {/* <!--====== Start Preloader ======--> */}
            {/* <div className="preloader">
                <div className="lds-ellipsis">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> */}
            {/* <!--====== End Preloader ======-->
            <!--====== Search From ======--> */}
            <div className="modal fade" id="search-modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="form_group">
                                <input type="text" className="form_control" placeholder="Search here..." />
                                <button className="search_btn"><i className="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!--====== Search From ======-->
            <!--====== offcanvas-panel ======--> */}
            <div className="panel-overlay"></div>
            <div className="offcanvas-panel">
                <div className="offcanvas-panel-inner">
                    <div className="panel-logo">
                        <a href="index.html"><img src="assets/images/logo/logo-1.png" alt="Qempo" /></a>
                    </div>
                    <div className="about-us">
                        <h5 className="panel-widget-title">About Us</h5>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="panel-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="fal fa-map-marker-alt"></i>
                                121 King St, Melbourne VIC 3000, Australia.
                            </li>
                            <li>
                                <i className="fal fa-envelope-open"></i>
                                <a href="mailto:hello@barky.com"> hello@barky.com</a>
                                <a href="mailto:info@barky.com">info@barky.com</a>
                            </li>
                            <li>
                                <i className="fal fa-phone"></i>
                                <a href="tel:(312)-895-9800">(312) 895-9800</a>
                                <a href="tel:(312)-895-9888">(312) 895-9888</a>
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="panel-close"><i className="fal fa-times"></i></a>
                </div>
            </div>
            {/* <!--====== offcanvas-panel ======--> */}

            {/* header in comonents */}

            {/* <!--====== Start Bredcumbs section ======--> */}
            <div className="new-menu-bar" style={{ position: "relative" }}>
                <div className="hero-shape">
                    <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
                    <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
                </div>
                <div className="header-bg-img" style={{ paddingTop: "100px", backgroundColor: "#DBF3F3" }}>
                    <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/blogs.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="page-title-inner">
                                        <ul className="bredcumbs-link">
                                            <li><Link to="/">Home</Link></li>
                                            {/* <li><a href="index.html">Home</a></li> */}
                                            <li className="active">Blog</li>
                                        </ul>
                                        <div className="title">
                                            <h1>Blog</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/bg-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}> */}
            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/blogs.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="page-title-inner">
                                <ul className="bredcumbs-link">
                                    <li><Link to="/">Home</Link></li>
                                    <li className="active">Blog</li>
                                </ul>
                                <div className="title">
                                    <h1>Blog</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Bredcumbs section ======--> */}
            {/* <!--====== Start Blog Section ======--> */}
            <section className="blog-grid-four pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* {myblog} */}
                        <Blog items={myActiveData} />

                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12" style={{ paddingLeft: "20px" }}>
                            <button id="prevbtn" type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingTop: "15px", paddingBottom: "15px", marginRight: "20px", marginBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }} disabled="true" >
                                Previous
                            </button>
                            <button id="nextbtn" type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingLeft: "40px", paddingRight: "40px", paddingTop: "15px", paddingBottom: "15px", marginBottom: "10px" }} >
                                Next
                            </button>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!--====== End Blog Section ======--> */}

            {/* footer in components */}

            {/* <!--====== back-to-top ======--> */}
            <a href="#" className="back-to-top" ><i className="fas fa-angle-up"></i></a>
        </>
    )
}
