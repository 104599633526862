import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { API, apiBaseURL } from '../axios';
import DOMPurify from 'dompurify';

export default function ServiceDetailsNew(params) {
    // console.log(params.id);
    const [myData, setmyData] = useState([]);
    const [myServiceList, setmyServiceList] = useState([]);
    const [isError, setisError] = useState("");
    // calling the function
    useEffect(() => {
        document.title = 'Amvean - Services';
        console.log("service-details-useeffect");
        console.log(params.slug);
        getMyPostData();
        getMyServiceData();
    }, [params.slug]);

    // using asynk and await
    const getMyPostData = async () => {
        // console.log('get my post data called');
        try {
            // console.log('get my post data called in try');
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // console.log('get my post data called in try now get data');
            const mdata = { 'DetailSlug': params.slug };
            console.log(mdata);
            const res = await API.post(`details/get-content-details-by-slug/`, mdata, myheaders);
            // const res = await API.get(`details/get-content-details-by-id/` + params.id, myheaders);
            // console.log("in details-new");
            // console.log(res);
            console.log(res.data.message);
            setmyData(res.data.data);
            console.log(myData);
        } catch (error) {
            console.log('get my post data called in catch');
            console.log(error.message);
            setisError(error.message);
        }
    };

    const getMyServiceData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            const res = await API.get(`/content/list/2`, myheaders);
            console.log("service-page");
            setmyServiceList(res.data.contentdetails.services);
            console.log(myServiceList);
        } catch (error) {
            console.log("service-page error");
            setisError(error.message);
        }
    };

    const myservices = Object.values(myServiceList).filter(service =>
        service.DetailSlug !== params.slug
    );

    const consturlimg = apiBaseURL.baseURL;

    return (
        <>
            <Header logo="white" />
            {/* <!--====== Start Preloader ======--> */}
            {/* <div className="preloader">
                <div className="lds-ellipsis">
                <span></span>
                <span></span>
                <span></span>
                </div>
            </div> */}

            {/* <!--====== End Preloader ======-->
        <!--====== Search From ======--> */}
            <div className="modal fade" id="search-modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="form_group">
                                <input type="text" className="form_control" placeholder="Search here..." />
                                <button className="search_btn"><i className="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!--====== Search From ======-->
        <!--====== offcanvas-panel ======--> */}
            <div className="panel-overlay"></div>
            <div className="offcanvas-panel">
                <div className="offcanvas-panel-inner">
                    <div className="panel-logo">
                        <a href="index.html"><img src="assets/images/logo/logo-1.png" alt="Qempo" /></a>
                    </div>
                    <div className="about-us">
                        <h5 className="panel-widget-title">About Us</h5>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="panel-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="fal fa-map-marker-alt"></i>
                                121 King St, Melbourne VIC 3000, Australia.
                            </li>
                            <li>
                                <i className="fal fa-envelope-open"></i>
                                <a href="mailto:hello@barky.com"> hello@barky.com</a>
                                <a href="mailto:info@barky.com">info@barky.com</a>
                            </li>
                            <li>
                                <i className="fal fa-phone"></i>
                                <a href="tel:(312)-895-9800">(312) 895-9800</a>
                                <a href="tel:(312)-895-9888">(312) 895-9888</a>
                            </li>
                        </ul>
                    </div>
                    <a href="/" className="panel-close"><i className="fal fa-times"></i></a>
                </div>
            </div>
            {/* <!--====== offcanvas-panel ======--> */}

            {/* header in components */}

            {/* <!--====== Start Bredcumbs section ======--> */}
            <div className="new-menu-bar" style={{ position: "relative" }}>
                <div className="hero-shape">
                    <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
                    <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
                </div>
                <div className="header-bg-img" style={{ paddingTop: "100px", backgroundColor: "#DBF3F3" }}>
                    <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/service-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="page-title-inner">
                                        <ul className="bredcumbs-link">
                                            <li><Link to="/">Home</Link></li>
                                            <li className="active">Service Details</li>
                                        </ul>
                                        <div className="title">
                                            {myData.map((heading) =>
                                                <h1>{heading.MainHeading}</h1>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/bg-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}> */}
            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/service-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="page-title-inner">
                                <ul className="bredcumbs-link">
                                    <li><Link to="/">Home</Link></li>
                                    <li className="active">Service Details</li>
                                </ul>
                                <div className="title">
                                    {myData.map((heading) =>
                                        <h1>{heading.MainHeading}</h1>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Bredcumbs section ======-->
        <!--====== Start Blog section ======--> */}
            <section className="blog-details-section pt-120 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details-wrapper wow fadeInUp mb-40">
                                <div className="blog-post-item" id="service-desc">
                                    {myData.map((desc) =>
                                        <div className="entry-content">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc.MainDescription) }}></p>
                                                </div>
                                                {/* <div className="col-sm-4">
                                                    <img src={desc.SubImage1 ? consturlimg + desc.SubImage1 : "./assets/images/service/layer-5.png"} className="img-fluid" alt="" />
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc.SubDescription) }}></p>
                                                {/* <p><h2>hello world</h2></p> */}
                                            </div>
                                        </div>
                                    )}
                                    {/* <div className="entry-content">
                                        <div className="post-meta mb-20">
                                            <span><i className="far fa-user" style={{ marginRight: "5px" }}></i>{myData.map((author) => <a href="/">{author.BlogAuthor}</a>
                                            )}</span>
                                            <ul>
                                                <li><span><i className="fal fa-eye"></i><a href="/">100 Views</a></span></li>
                                                <li><span><i className="fal fa-comments"></i><a href="/">23 Comments</a></span></li>
                                                <li><span><i className="fal fa-calendar-alt"></i>{myData.map((date) => <a href="/">{date.Created}</a>
                                                )}</span></li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                            </div>
                                            <div className="col-sm-4">
                                                <img src="./assets/images/home/hero-img.png" alt="Hero Image" />
                                            </div>
                                        </div>

                                        {myData.map((desc) => <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc.MainDescription) }}></p>
                                        )}

                                        {myData.map((desc) => <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc.SubDescription) }}></p>
                                        )}

                                        <div className='row'>
                                            <div className="col-sm-6">
                                                {myData.map((link) => <Link to={"/blog-details-" + link.PrevLink}><button type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingTop: "15px", paddingBottom: "15px", marginRight: "20px", marginBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>Previous</button></Link>)}

                                                {myData.map((link) => <Link to={"/blog-details-" + link.NextLink}><button type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingLeft: "40px", paddingRight: "40px", paddingTop: "15px", paddingBottom: "15px", marginBottom: "10px" }}>Next</button></Link>)}
                                            </div>
                                            <div className="col-sm-6">
                                                <div className='d-flex justify-content-end'>
                                                    <div className="row">
                                                        <h5>Social Share</h5>
                                                        <ul className="social-link">
                                                            <li><a href="#" style={{ margin: "5px" }}><i class="fab fa-facebook-f"></i></a></li>
                                                            <li><a href="#" style={{ margin: "5px" }}><i class="fab fa-twitter"></i></a></li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                {/* details ends */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-widget-area">
                                <div className="widget recent-post-widget mb-40 wow fadeInUp">
                                    <h4 className="widget-title">Services</h4>
                                    <ul className="recent-post-list">
                                        {myservices.map((service, index) =>
                                            <li className="post-thumbnail-content" key={index}>
                                                <img src={service.Image ? service.Image : "assets/images/blog/thumb-44.jpg"} className="img-fluid" alt="" />
                                                {/* <img src={consturlimg + service.Image} className="img-fluid" alt="" /> */}
                                                <div className="post-title-date">
                                                    <h6><Link to={"/" + service.DetailSlug}>{service.MainHeading}</Link></h6>
                                                    {/* <h6><a href="blog-details.html">{service.MainHeading}</a></h6> */}
                                                </div>
                                            </li>
                                        )}
                                        {/* <li className="post-thumbnail-content">
                                            <img src="assets/images/blog/thumb-44.jpg" className="img-fluid" alt="" />
                                            <div className="post-title-date">
                                                <h6><a href="blog-details.html">What is the crypto virus?</a></h6>
                                                <span className="posted-on"><i className="far fa-calendar-alt"></i><a href="/">24 Mar, 2021</a></span>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== End Blog section ======--> */}
            {/* <!--====== Start Features section ======--> */}
            {/* <section className="features-area-v5 pt-110 pb-65">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center mb-80">
                                <span className="span">Working Process</span>
                                <h2>How It Works</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 features-column">
                            <div className="features-item text-center mb-35">
                                <div className="features-img">
                                    <img src="assets/images/features/illustration-1.png" alt="" />
                                </div>
                                <div className="content">
                                    <h4>Discussions About Project</h4>
                                    <p>a business or organization providing a
                                        particular service on behalf of another
                                        business, person, or group.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 features-column">
                            <div className="features-item text-center mb-35">
                                <div className="features-img">
                                    <img src="assets/images/features/illustration-2.png" alt="" />
                                </div>
                                <div className="content">
                                    <h4>Start Work With Team</h4>
                                    <p>a business or organization providing a
                                        particular service on behalf of another
                                        business, person, or group.</p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 features-column">
                            <div className="features-item text-center mb-35">
                                <div className="features-img">
                                    <img src="assets/images/features/illustration-3.png" alt="" />
                                </div>
                                <div className="content">
                                    <h4>Handover & Save World</h4>
                                    <p>a business or organization providing a
                                        particular service on behalf of another
                                        business, person, or group.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Features section ======--> */}

            {/* footer in components */}

            {/* <!--====== back-to-top ======--> */}
            <a href="/" className="back-to-top" ><i className="fas fa-angle-up"></i></a>
        </>
    )
}
