import { React, useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../components/Header';
import { API, apiBaseURL } from '../axios';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';


export default function BlogDetails(params) {

  const [myData, setmyData] = useState([]);
  const [isError, setisError] = useState("");
  const [mytitle, setmytitle] = useState("");

  // calling the function
  useEffect(() => {
    // console.log('use effect');
    document.title = 'Amvean - Blogs';
    getMyPostData();
    getAllBlogs();
  }, [params.id]);

  // using asynk and await
  const getMyPostData = async () => {
    // console.log('get my post data called');
    try {
      // console.log('get my post data called in try');
      const myheaders = {
        headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
      };
      // console.log('get my post data called in try now get data');
      //const res = await API.get(`/blogs/get-blog-by-id/` + params.id, myheaders);
      const res = await API.get(`blogs/get-blog-by-slug/` + params.slug, myheaders);
      console.log('blog details data');
      console.log(res.data.data);
      console.log(res.data.message);
      setmyData(res.data.data);
      console.log(res.data.data[0].BlogTitle);
      setmytitle(res.data.data[0].BlogTitle);
    } catch (error) {
      // console.log('get my post data called in catch');
      console.log(error.message);
      setisError(error.message);
    }
  };

  const [allBlogs, setallBlogs] = useState([]);
  const [blogError, setblogError] = useState("");
  const getAllBlogs = async () => {
    // console.log('get my post data called');
    try {
      // console.log('get my post data called in try');
      const myheaders = {
        headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
      };
      // console.log('get my post data called in try now get data');
      const res = await API.get(`/blogs/list`, myheaders);
      console.log(res.data.message);
      setallBlogs(res.data.data);
      console.log("all blogs");
      console.log(allBlogs);
    } catch (error) {
      // console.log('get my post data called in catch');
      console.log(error.message);
      setblogError(error.message);
    }
  };

  const recentPostBlogs = allBlogs.filter(blog =>
    blog.BlogSlug !== params.slug
  );

  // console.log(recentPostBlogs);
  const consturlimg = apiBaseURL.baseURL;

  console.log("my title");
  console.log(mytitle);

  return (
    <>
      <Header logo="white" />
      {/* <!--====== Start Preloader ======--> */}
      {/* <div className="preloader">
        <div className="lds-ellipsis">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div> */}

      {/* <!--====== End Preloader ======-->
        <!--====== Search From ======--> */}
      <div className="modal fade" id="search-modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form>
              <div className="form_group">
                <input type="text" className="form_control" placeholder="Search here..." />
                <button className="search_btn"><i className="fa fa-search"></i></button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!--====== Search From ======-->
        <!--====== offcanvas-panel ======--> */}
      <div className="panel-overlay"></div>
      <div className="offcanvas-panel">
        <div className="offcanvas-panel-inner">
          <div className="panel-logo">
            <a href="index.html"><img src="assets/images/logo/logo-1.png" alt="Qempo" /></a>
          </div>
          <div className="about-us">
            <h5 className="panel-widget-title">About Us</h5>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
            </p>
          </div>
          <div className="contact-us">
            <h5 className="panel-widget-title">Contact Us</h5>
            <ul>
              <li>
                <i className="fal fa-map-marker-alt"></i>
                121 King St, Melbourne VIC 3000, Australia.
              </li>
              <li>
                <i className="fal fa-envelope-open"></i>
                <a href="mailto:hello@barky.com"> hello@barky.com</a>
                <a href="mailto:info@barky.com">info@barky.com</a>
              </li>
              <li>
                <i className="fal fa-phone"></i>
                <a href="tel:(312)-895-9800">(312) 895-9800</a>
                <a href="tel:(312)-895-9888">(312) 895-9888</a>
              </li>
            </ul>
          </div>
          <a href="/" className="panel-close"><i className="fal fa-times"></i></a>
        </div>
      </div>
      {/* <!--====== offcanvas-panel ======--> */}

      {/* header in components */}

      {/* <!--====== Start Bredcumbs section ======--> */}
      <div className="new-menu-bar" style={{ position: "relative" }}>
        <div className="hero-shape">
          <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
          <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
        </div>
        <div className="header-bg-img" style={{ paddingTop: "100px", backgroundColor: "#DBF3F3" }}>
          <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/blogs.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="page-title-inner">
                    <ul className="bredcumbs-link">
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/blog">Blogs</Link></li>
                      {myData.map((heading) =>
                        <li className="active">{heading.BlogTitle}</li>
                      )}
                    </ul>
                    <div className="title">
                      {myData.map((heading) =>
                        <h1>{heading.BlogTitle}</h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/bg-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}> */}
      {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/blogs.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="page-title-inner">
                <ul className="bredcumbs-link">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/blogs">Blogs</Link></li>
                  <li><a href="index.html">Blog</a></li>
                  {myData.map((heading) =>
                    <li className="active">{heading.BlogTitle}</li>
                  )}
                  <li className="active">Network and Phone System Install</li>
                </ul>
                <div className="title">
                  {myData.map((heading) =>
                    <h1>{heading.BlogTitle}</h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!--====== End Bredcumbs section ======-->
        <!--====== Start Blog section ======--> */}
      <section className="blog-details-section pt-120 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details-wrapper wow fadeInUp mb-40">
                <div className="blog-post-item">
                  <div className="entry-content">
                    <div className="post-meta mb-20">
                      <span><i className="far fa-user" style={{ marginRight: "5px" }}></i>{myData.map((author) => <a href="/">{author.BlogAuthor}</a>
                      )}</span>
                      <ul>
                        {/* <li><span><i className="fal fa-eye"></i><a href="/">100 Views</a></span></li>
                        <li><span><i className="fal fa-comments"></i><a href="/">23 Comments</a></span></li> */}
                        <li><span><i className="fal fa-calendar-alt"></i>{myData.map((date) => <a href="/">{date.Created.split('T')[0]}</a>
                        )}</span></li>
                        {/* <li><span><i className="fal fa-calendar-alt"></i><a href="/">24th March 2020</a></span></li> */}
                      </ul>
                    </div>
                    {/* <div className="block-image mb-50">
                      <img src="assets/images/blog/layer-2.png" alt="" />
                    </div> */}
                    {myData.map((desc) => <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc.BlogDescription) }}></p>
                    )}
                    {/* {myData.map((disc) => <p>{disc.BlogDescription}</p>
                    )} */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explic abo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur mag
                      ni dolores eos qui ratione voluptatem sequi nesciunt.</p> */}

                    {/* <h3 >Recent Customers:</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consect etur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <div className="block-image mb-50">
                      <div style={{ padding: "0 150px 0 150px" }}>
                        <img src="assets/images/blog/cisco-508.png" alt="" />
                      </div>
                      <h5 style={{ color: "#ff774a", textAlign: "center" }}>Cisco SPA 508 phone(The phone used for the clients)</h5>
                    </div>
                    <p>Product specification(Information from Amazon website)</p>

                    <h3>Highlights</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consect etur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <div className="block-image mb-50">
                      <div style={{ padding: "0 150px 0 150px" }}>
                        <img src="assets/images/blog/layer-3.png" alt="" />
                      </div>
                      <h5 style={{ color: "#ff774a", textAlign: "center" }}>Cisco UC520 Phone System</h5>
                    </div>
                    <p>Product specification(Information from Amazon website)</p>
                    <div style={{ marginLeft: "15px", marginBottom: "30px" }}>
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                      </ul>
                    </div>

                    <h3>PSTN connectivity</h3>
                    <div style={{ marginLeft: "15px", marginBottom: "30px" }}>
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                      </ul>
                    </div>

                    <h3>Secure Network Foundation</h3>
                    <div style={{ marginLeft: "15px", marginBottom: "30px" }}>
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                      </ul>
                    </div>

                    <h3>Applications</h3>
                    <div style={{ marginLeft: "15px", marginBottom: "30px" }}>
                      <ul style={{ listStyleType: 'disc' }}>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                      </ul>
                    </div>

                    <h3>UC5290 Security Features:</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consect etur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>

                    <h3>UC5290 Wireless Features:</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consect etur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>

                    <h3>ISO Feature set based on Advance IP Services</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consect etur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p> */}

                    {/* <p style={{ color: "#fd6151" }}>
                      <a href="/">#cabling,</a>
                      <a href="/">#cisco business phone setup,</a>
                      <a href="/">#install phone,</a>
                      <a href="/">#New Phone System Installer,</a>
                      <a href="/">#phone system</a>
                    </p> */}
                    <div className='row'>
                      <div className="col-sm-6">
                        {myData.map((link) =>
                          <button type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingTop: "15px", paddingBottom: "15px", marginRight: "20px", marginBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }} disabled={link.PrevLink ? false : true}><Link to={"/" + link.PrevLink}>Previous</Link></button>
                        )}

                        {myData.map((link) =>
                          <button type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingLeft: "40px", paddingRight: "40px", paddingTop: "15px", paddingBottom: "15px", marginBottom: "10px" }} disabled={link.NextLink ? false : true}><Link to={"/" + link.NextLink}>Next</Link></button>
                        )}
                        {/* <button type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingTop: "15px", paddingBottom: "15px", marginRight: "20px", marginBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>{myData.map((link) => <Link to={"/blog-details-" + link.PrevLink}>Previous</Link>)}</button> */}
                        {/* <button type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingLeft: "40px", paddingRight: "40px", paddingTop: "15px", paddingBottom: "15px", marginBottom: "10px" }}>
                          Next
                        </button> */}
                      </div>
                      <div className="col-sm-6">
                        <div className='d-flex justify-content-end'>
                          <div className="row">
                            <h5>Social Share</h5>
                            <ul className="social-link">
                              <li><a href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(document.location.href)} target="_blank" style={{ margin: "5px" }}><i class="fab fa-facebook-f"></i></a></li>
                              <li><a href={"https://twitter.com/share?url=" + encodeURIComponent(document.location.href) + "&text=" + encodeURIComponent(mytitle) + ""} target="_blank" style={{ margin: "5px" }}><i class="fab fa-twitter"></i></a></li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* details ends */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-widget-area">
                <div className="widget recent-post-widget mb-40 wow fadeInUp">
                  <h4 className="widget-title">Recent Posts</h4>
                  <ul className="recent-post-list">
                    {recentPostBlogs.slice(0, 4).map((blog) =>
                      <li className="post-thumbnail-content">
                        <img src={consturlimg + blog.BlogImage} className="img-fluid" alt="" />
                        <div className="post-title-date">
                          <h6><Link to={"/" + blog.BlogSlug}>{blog.BlogTitle}</Link></h6>
                          <span className="posted-on"><i className="far fa-calendar-alt"></i>{blog.Created.split('T')[0]}</span>
                        </div>
                      </li>
                    )}
                    {/* <li className="post-thumbnail-content">
                      <img src="assets/images/blog/thumb-11.jpg" className="img-fluid" alt="" />
                      <div className="post-title-date">
                        <h6><a href="blog-details.html">Network and Phone System Install</a></h6>
                        <span className="posted-on"><i className="far fa-calendar-alt"></i><a href="/">24 Mar, 2021</a></span>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--====== End Blog section ======--> */}

      {/* footer in components */}

      {/* <!--====== back-to-top ======--> */}
      <a href="/" className="back-to-top" ><i className="fas fa-angle-up"></i></a>
    </>
  )
}
