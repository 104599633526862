import { React, useState, useEffect } from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { API, apiBaseURL } from '../axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function ProductPage() {

    const [section1data, setsection1data] = useState([]);
    const [section2data, setsection2data] = useState({});
    const [section3data, setsection3data] = useState({});
    const [techPartner, settechPartner] = useState({});
    const [isError, setisError] = useState("");


    useEffect(() => {
        document.title = 'Amvean - Projects';
        getMyPostData();
    }, []);

    const getMyPostData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // const ddata = { 'DetailType': 'projects' };
            const res = await API.get(`/content/list/3`, myheaders);
            console.log("in projects");
            // const { data } = await axios.post(`details/get-content-details/`, ddata, config)
            setsection1data(res.data.content);
            setsection2data(res.data.contentdetails.projects);
            setsection3data(res.data.contentdetails['request-quote']);
            settechPartner(res.data.contentdetails['technology-partner']);
            console.log("hello");
            console.log(techPartner);
        } catch (error) {
            console.log(error.message);
            setisError(error.message);
        }
    };

    const consturlimg = apiBaseURL.baseURL;

    const options2 = {
        loop: true,
        center: true,
        items: 5,
        margin: 0,
        autoplay: true,
        dots: false,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 3
            },
            600: {
                items: 5
            },
            1000: {
                items: 5
            }
        }
    };

    // const cards = test.map((card) =>
    //     <div className="col-lg-4" style={{ padding: "0 20px 20px 20px", position: "relative" }}>
    //         <div className="my-card" style={{ background: "linear-gradient(transparent, rgba(254, 102, 80, 0.9)), url(" + consturlimg + section2datac1.Image + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "300px", boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.2)" }}>
    //             <div className="text-block" style={{ position: "absolute", bottom: "8px", paddingLeft: "10px", paddingRight: "20px", paddingBottom: "10px" }}>
    //                 <h4 className="card-title" style={{ color: "white" }}>{section2datac1.DetailHeading}</h4>
    //             </div>
    //         </div>
    //     </div>
    // );

    const myActiveData = Object.values(section2data).filter(product =>
        product.Status === 1
    )

    const myActiveTechPartData = Object.values(techPartner).filter(techPart =>
        techPart.Status === 1
    )

    return (
        <>
            <Header logo="white" />
            {/* <!--====== Start Preloader ======--> */}
            {/* <div className="preloader">
                <div className="lds-ellipsis">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> */}
            {/* <!--====== End Preloader ======--> */}
            {/* <!--====== Search From ======--> */}
            <div className="modal fade" id="search-modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="form_group">
                                <input type="text" className="form_control" placeholder="Search here..." />
                                <button className="search_btn"><i className="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!--====== Search From ======--> */}
            {/* <!--====== offcanvas-panel ======--> */}
            <div className="panel-overlay"></div>
            <div className="offcanvas-panel">
                <div className="offcanvas-panel-inner">
                    <div className="panel-logo">
                        <a href="index.html"><img src="assets/images/logo/logo-1.png" alt="Qempo" /></a>
                    </div>
                    <div className="about-us">
                        <h5 className="panel-widget-title">About Us</h5>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="panel-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="fal fa-map-marker-alt"></i>
                                121 King St, Melbourne VIC 3000, Australia.
                            </li>
                            <li>
                                <i className="fal fa-envelope-open"></i>
                                <a href="mailto:hello@barky.com"> hello@barky.com</a>
                                <a href="mailto:info@barky.com">info@barky.com</a>
                            </li>
                            <li>
                                <i className="fal fa-phone"></i>
                                <a href="tel:(312)-895-9800">(312) 895-9800</a>
                                <a href="tel:(312)-895-9888">(312) 895-9888</a>
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="panel-close"><i className="fal fa-times"></i></a>
                </div>
            </div>
            {/* <!--====== offcanvas-panel ======--> */}

            {/* header in components */}

            {/* <!--====== Start Bredcumbs section ======--> */}
            <div className="new-menu-bar" style={{ position: "relative" }}>
                <div className="hero-shape">
                    <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
                    <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
                </div>
                <div className="header-bg-img" style={{ paddingTop: "100px", backgroundColor: "#DBF3F3" }}>
                    {section1data.map((jumbo) =>
                        <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/projects.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="page-title-inner">
                                            <ul className="bredcumbs-link">
                                                <li><Link to="/">{jumbo.JumbotronNavLink1Title}</Link></li>
                                                <li className="active">{jumbo.JumbotronNavLink2Title}</li>
                                            </ul>
                                            <div className="title">
                                                <h1>{jumbo.JumbotronNavLink2Title}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
            {/* {section1data.map((jumbo) =>
                // <section className="bredcumbs-area bg_cover" style={{ background: "url(" + consturlimg + jumbo.JumbotronImage + ")", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/projects.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="page-title-inner">
                                    <ul className="bredcumbs-link">
                                        <li><Link to="/">{jumbo.JumbotronNavLink1Title}</Link></li>
                                        <li><a href="index.html">Home</a></li>
                                        <li className="active">{jumbo.JumbotronNavLink2Title}</li>
                                    </ul>
                                    <div className="title">
                                        <h1>{jumbo.JumbotronNavLink2Title}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )} */}
            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(" + consturlimg + section1data.JumbotronImage + ")", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="page-title-inner">
                                <ul className="bredcumbs-link">
                                    <li><Link to="/">{section1data.JumbotronNavLink1Title}</Link></li>
                                    <li><a href="index.html">Home</a></li>
                                    <li className="active">{section1data.JumbotronNavLink2Title}</li>
                                </ul>
                                <div className="title">
                                    <h1>{section1data.JumbotronNavLink2Title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Bredcumbs section ======--> */}
            {/* <!--====== Start Portfolio Section ======--> */}
            <div className="new-protfolio">
                <div className="container" style={{ padding: "50px 0 150px 0" }}>
                    <div className="row" style={{ paddingTop: "50px" }}>
                        <div className="row" style={{ paddingTop: "50px" }}>
                            {myActiveData.map((card, index) =>
                                <div className="col-lg-4" style={{ padding: "0 20px 20px 20px", position: "relative" }} key={index}>
                                    <div className="my-card" style={{ background: "linear-gradient(transparent, rgba(254, 102, 80, 0.9)), url(" + card.Image + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "300px", boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.2)" }}>
                                        <div className="text-block" style={{ position: "absolute", bottom: "8px", paddingLeft: "10px", paddingRight: "20px", paddingBottom: "10px" }}>
                                            <h4 className="card-title" style={{ color: "white", width: "90%" }}>{card.DetailHeading}</h4>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* <div className="col-lg-4" style={{ padding: "0 20px 20px 20px", position: "relative" }}>
                                <div className="my-card" style={{ background: "linear-gradient(transparent, rgba(254, 102, 80, 0.9)), url(" + consturlimg + section2datac1.Image + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "300px", boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.2)" }}>
                                    <div className="text-block" style={{ position: "absolute", bottom: "8px", paddingLeft: "10px", paddingRight: "20px", paddingBottom: "10px" }}>
                                        <h4 className="card-title" style={{ color: "white" }}>{section2datac1.DetailHeading}</h4>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">

                        </div>
                    </div>
                </div>
            </div>

            {/* <!--====== End Portfolio Section ======--> */}
            {/* <!--====== Start CTA Section ======--> */}
            <section className="cta-area-v4 bg_cover parallax-bg pt-110 pb-70">
                <div className="container">
                    {Object.values(section3data).map((s3, index) =>
                        <div className="row align-items-center" key={index}>
                            <div className="col-lg-8">
                                <div className="section-title section-title-white mb-50">
                                    <span className="span">{s3.MainSubHeading}</span>
                                    <h2>{s3.MainHeading}</h2>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="button mb-50">
                                    <Link to="/contact-us" className="main-btn active-btn">{s3.Button1Title}</Link>
                                    <Link to="/about" className="main-btn"> <i className="fas fa-play"></i>{s3.Button2Title}</Link>
                                    {/* <a href="contact.html" className="main-btn active-btn" style={{ backgroundColor: "black" }}>Get Free Quote</a>
                                <a href="about-us.html" className="main-btn"><i className="fas fa-play"></i>Tour Video</a> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="section-title section-title-white mb-50">
                                <span className="span">{section3data.MainSubHeading}</span>
                                <h2>{section3data.MainHeading}</h2>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="button mb-50">
                                <Link to="/contact-us" className="main-btn active-btn">{section3data.Button1Title}</Link>
                                <Link to="/about-us" className="main-btn"> <i className="fas fa-play"></i>{section3data.Button2Title}</Link>
                                <a href="contact.html" className="main-btn active-btn" style={{ backgroundColor: "black" }}>Get Free Quote</a>
                                <a href="about-us.html" className="main-btn"><i className="fas fa-play"></i>Tour Video</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!--====== End CTA Section ======--> */}
            {/* <!--====== Start Company section ======--> */}
            <section className="clients-area-v3 pt-110 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="section-title mb-65">
                                <span className="span">Clients</span>
                                <h2>We worked with brands.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="d-flex flex-wrap justify-content-evenly" style={{ padding: "10px 0 30px 0" }}>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-1.jpg" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-1.jpg" alt="" /></a> */}
                            </div>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-2.png" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-2.png" alt="" /></a> */}
                            </div>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-3.png" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-3.png" alt="" /></a> */}
                            </div>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-4.png" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-4.png" alt="" /></a> */}
                            </div>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-5.png" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-5.png" alt="" /></a> */}
                            </div>
                        </div>
                        <div class="d-flex flex-wrap justify-content-evenly" style={{ padding: "10px 30px 30px 30px" }}>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-6.png" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-6.png" alt="" /></a> */}
                            </div>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-7.png" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-7.png" alt="" /></a> */}
                            </div>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-8.png" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-8.png" alt="" /></a> */}
                            </div>
                            <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about"><img src="assets/images/clients/client-9.jpg" alt="" /></Link>
                                {/* <a href="about-us.html"><img src="assets/images/clients/client-9.jpg" alt="" /></a> */}
                            </div>
                        </div>
                        <div class="d-flex flex-wrap justify-content-center" style={{ padding: "10px 0 30px 0" }}>

                        </div>
                    </div>

                </div>
            </section>
            {/* <!--====== End Company section ======--> */}
            {/* <!--====== Start Tech Partner section ======--> */}
            <section>
                <div className="container">
                    {/* {Object.values(techPartner).map((partner) =>
                        <div className="row" style={{ padding: "50px 0 50px 0" }}>
                            <div className="col-lg-2">
                                <h5 style={{ color: "#fd6350" }}>{partner.DetailHeading}</h5>
                            </div>
                            <div className="col-lg-10">
                                <img src={consturlimg + partner.Image} alt="" />
                            </div>
                        </div>
                    )} */}
                    <div className="row" style={{ padding: "50px 0 50px 0" }}>
                        <div className="col-lg-2">
                            <h5 style={{ color: "#fd6350" }}>Our Technology Partners</h5>
                        </div>
                        <div className="col-lg-10">
                            <OwlCarousel id="customer-testimonoals2" className="owl-carousel owl-theme" key={`carousel_${myActiveTechPartData.length}`} {...options2}>
                                {myActiveTechPartData.map((partner, index) =>
                                    <div className="portfolio-item clients-logo item px-2" key={index}>
                                        <div className="portfolio-img">
                                            <Link to="/" className="img-popup"><img src={partner.Image} alt="" /></Link>
                                        </div>
                                    </div>
                                )}
                            </OwlCarousel>
                        </div>
                    </div>
                    {/* <div className="row" style={{ padding: "50px 0 50px 0" }}>
                        <div className="col-lg-2">
                            <h5 style={{ color: "#fd6350" }}>Our Technology Partners</h5>
                        </div>
                        <div className="col-lg-10">
                            <img src="assets/images/clients/tech-partners-1.png" alt="" />
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!--====== End Tech Partner section ======--> */}

            {/* footer in components */}

            {/* <!--====== back-to-top ======--> */}
            <a href="#" className="back-to-top" ><i className="fas fa-angle-up"></i></a>
        </>
    )
}
