import { React, useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { API, apiBaseURL } from '../axios';
import DOMPurify from 'dompurify';

export default function AboutUs() {

    // const [myData, setmyData] = useState([]);
    const [section1data, setmyDataSection1] = useState([]);
    const [aboutdata, setmyaboutData] = useState([]);
    const [section3data, setmyDataSection3] = useState({});
    // const [section4data, setmyDataSection4] = useState([]);
    const [section5data, setmyDataSection5] = useState({});
    const [isError, setisError] = useState("");
    // calling the function
    useEffect(() => {
        // console.log('use effect');
        document.title = 'Amvean - About';
        getMyPostData();
    }, []);

    // using asynk and await
    const getMyPostData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // const res = await axios.post(`details/get-content-details/`, mdata, myheaders);
            const res = await API.get(`/content/list/5`, myheaders);
            console.log('get my post data called in about');
            console.log(res.data.message);
            setmyDataSection1(res.data.content);
            setmyaboutData(res.data.contentdetails['about-us']);
            setmyDataSection3(res.data.contentdetails['request-quote']);
            // setmyDataSection4(res.data.contentdetails['about-us'][11]);
            setmyDataSection5(res.data.contentdetails.brand);
            console.log(section1data);
            console.log("about sections in about");
            console.log(aboutdata);
            console.log(section3data);
        } catch (error) {
            console.log(error);
            setisError(error.message);
        }
    };

    const about1 = Object.values(aboutdata).filter(a1 =>
        a1.SortOrder === 1
    );
    const about2 = Object.values(aboutdata).filter(a2 =>
        a2.SortOrder === 2
    );
    console.log(about1);
    console.log(about2);

    const contentdescref1 = useRef(null);
    if (contentdescref1.current) {
        contentdescref1.current.innerHTML = section1data.MainDescription;
    };

    const contentdescref3 = useRef(null);
    if (contentdescref3.current) {
        contentdescref3.current.innerHTML = section3data.MainDescription;
    };

    const subdesc = useRef(null);
    if (subdesc.current) {
        subdesc.current.innerHTML = section1data.SubDescription;
        // subdesc.current.insertAdjacentHTML("afterbegin", section1data.SubDescription);
    };

    const consturlimg = apiBaseURL.baseURL;

    const myActiveClientsData = Object.values(section5data).filter(client =>
        client.Status === 1
    )

    return (
        <>
            <Header logo="white" />
            {/* <!--====== Start Preloader ======--> */}
            {/* <div className="preloader">
                <div className="lds-ellipsis">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> */}
            {/* <!--====== End Preloader ======--> */}
            {/* <!--====== Search From ======--> */}
            <div className="modal fade" id="search-modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="form_group">
                                <input type="text" className="form_control" placeholder="Search here..." />
                                <button className="search_btn"><i className="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!--====== Search From ======--> */}
            {/* <!--====== offcanvas-panel ======--> */}
            <div className="panel-overlay"></div>
            <div className="offcanvas-panel">
                <div className="offcanvas-panel-inner">
                    <div className="panel-logo">
                        <a href="index.html"><img src="assets/images/logo/logo-1.png" alt="Qempo" /></a>
                    </div>
                    <div className="about-us">
                        <h5 className="panel-widget-title">About Us</h5>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="panel-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="fal fa-map-marker-alt"></i>
                                121 King St, Melbourne VIC 3000, Australia.
                            </li>
                            <li>
                                <i className="fal fa-envelope-open"></i>
                                <a href="mailto:hello@barky.com"> hello@barky.com</a>
                                <a href="mailto:info@barky.com">info@barky.com</a>
                            </li>
                            <li>
                                <i className="fal fa-phone"></i>
                                <a href="tel:(312)-895-9800">(312) 895-9800</a>
                                <a href="tel:(312)-895-9888">(312) 895-9888</a>
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="panel-close"><i className="fal fa-times"></i></a>
                </div>
            </div>
            {/* <!--====== offcanvas-panel ======--> */}

            {/* header in components */}

            {/* <!--====== Start Bredcumbs section ======--> */}
            <div className="new-menu-bar" style={{ position: "relative" }}>
                <div className="hero-shape">
                    <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
                    <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
                </div>
                <div className="header-bg-img" style={{ paddingTop: "100px", backgroundColor: "#DBF3F3" }}>
                    {section1data.map((jumbotron) =>
                        <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/about-us.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="page-title-inner">
                                            <ul className="bredcumbs-link">
                                                <li><Link to="/">{jumbotron.JumbotronNavLink1Title}</Link></li>
                                                <li className="active">{jumbotron.JumbotronNavLink2Title}</li>
                                            </ul>
                                            <div className="title">
                                                <h1>About Us</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
            {/* {section1data.map((jumbotron) =>
                // <section className="bredcumbs-area bg_cover" style={{ background: "url(" + consturlimg + jumbotron.JumbotronImage + ")", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/about-us.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="page-title-inner">
                                    <ul className="bredcumbs-link">
                                        <li><Link to="/">{jumbotron.JumbotronNavLink1Title}</Link></li>
                                        <li><a href="index.html">Home</a></li>
                                        <li className="active">{jumbotron.JumbotronNavLink2Title}</li>
                                    </ul>
                                    <div className="title">
                                        <h1>About Us</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )} */}
            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/bg-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="page-title-inner">
                                <ul className="bredcumbs-link">
                                    <li><Link to="/">Home</Link></li>
                                    <li><a href="index.html">Home</a></li>
                                    <li className="active">About</li>
                                </ul>
                                <div className="title">
                                    <h1>About Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Bredcumbs section ======--> */}
            {/* <!--====== Start About section ======--> */}
            <section className="about-area-v4 pt-170 pb-120">
                <div className="container">
                    {about1.map((about) =>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-img">
                                    <img src={consturlimg + about.Image} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-content-box">
                                    <div className="section-title mb-35 wow fadeInUp">
                                        <span className="span">{about.MainSubHeading}</span>
                                        <h3>{about.MainHeading}</h3>
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about.MainDescription) }}></p>
                                    <div className="box wow fadeInUp">
                                        {/* <div className="thumb">
                                            <img src={consturlimg + about.SubImage1} alt="" />
                                        </div>
                                        <div className="info">
                                            <h4>{about.SubHeading}</h4>
                                            <p> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about.SubDescription) }}></span> <br /> <Link to="/blog-list" style={{ color: "#fd6350", borderBottom: "1px solid #fd6350", fontWeight: "700", fontSize: "18px", lineHeight: "18px" }}>{about.Button1Title}<i class="far fa-arrow-right"></i></Link></p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src={consturlimg + section2data.Image} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-box">
                                <div className="section-title mb-35 wow fadeInUp">
                                    <span className="span">{section2data.MainSubHeading}</span>
                                    <h3>{section2data.MainHeading}</h3>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section2data.MainDescription) }}></p>
                                <div className="box wow fadeInUp">
                                    <div className="thumb">
                                        <img src={consturlimg + section2data.SubImage1} alt="" />
                                    </div>
                                    <div className="info">
                                        <h4>{section2data.SubHeading}</h4>
                                        <p> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section2data.SubDescription) }}></span> <br /> <Link to="/blog-list" style={{ color: "#fd6350", borderBottom: "1px solid #fd6350", fontWeight: "700", fontSize: "18px", lineHeight: "18px" }}>{section2data.Button1Title}<i class="far fa-arrow-right"></i></Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <section className="about-area-v4 pt-170 pb-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="assets/images/about/about-66.jpg" alt="" />
                                <img src={"http://localhost:7000/uploads/about-66.jpg"} alt="" />
                                <img src={consturlimg + section1data.Image} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-box">
                                <div className="section-title mb-35 wow fadeInUp">
                                    <span className="span">{section1data.MainSubHeading}</span>
                                    <h3>{section1data.MainHeading}</h3>
                                    <h3>Gathering actionable intelligence in customized databases that drive strategic decisions</h3>
                                </div>
                                <div ref={contentdescref1}></div>
                                <p>In social science, agency is defined as the capacity of individuals to
                                    act independently and to make their own free choices. By contrast,
                                    structure are those factors of influence (such as social className, religion,
                                    gender, ethnicity, ability, customs, etc.)</p>
                                <div className="box wow fadeInUp">
                                    <div className="thumb">
                                        <img src={consturlimg + section1data.SubImage1} alt="" />
                                        <img src="assets/images/about/about-77.jpg" alt="" />
                                    </div>
                                    <div className="info">
                                        <h4>{section1data.SubHeading}</h4>
                                        <h4>Lets connect and discuss your project.</h4>
                                        <p> <span ref={subdesc}></span> <br /> <Link to="/blog-list" style={{ color: "#fd6350", borderBottom: "1px solid #fd6350", fontWeight: "700", fontSize: "18px", lineHeight: "18px" }}>{section1data.Button1Title}<i class="far fa-arrow-right"></i></Link></p>
                                        <p>{section1data.SubDescription}<br /> <a href="blog-details.html" className="btn-link">{section1data.Button1Title}</a></p>
                                        <p>Check out the current <br /> <a href="blog-details.html" className="btn-link">Connect Now</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End About section ======--> */}
            {/* <!--====== Start Counter section ======--> */}
            <section className="counter-area-v2 light-bg pt-25 pb-25">
                {/* <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center mb-80">
                                <span className="span">Fun Fact</span>
                                <h2>Our Company Growth</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                            <div className="counter-item text-center wow fadeInUp" data-wow-delay=".2s">
                                <div className="icon">
                                    <img src="assets/images/icon/icon-1.png" alt=""/>
                                </div>
                                <div className="info">
                                    <h2><span className="counter">500</span><span className="sign">+</span></h2>
                                    <p>Staticfied Customers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                            <div className="counter-item text-center wow fadeInUp" data-wow-delay=".3s">
                                <div className="icon">
                                    <img src="assets/images/icon/icon-2.png" alt=""/>
                                </div>
                                <div className="info">
                                    <h2><span className="counter">10</span>K<span className="sign">+</span></h2>
                                    <p>Wroteable Code</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                            <div className="counter-item text-center wow fadeInUp" data-wow-delay=".5s">
                                <div className="icon">
                                    <img src="assets/images/icon/icon-3.png" alt=""/>
                                </div>
                                <div className="info">
                                    <h2><span className="counter">200</span><span className="sign">+</span></h2>
                                    <p>Projects Done</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 counter-column">
                            <div className="counter-item text-center wow fadeInUp" data-wow-delay=".7s">
                                <div className="icon">
                                    <img src="assets/images/icon/icon-4.png" alt=""/>
                                </div>
                                <div className="info">
                                    <h2><span className="counter">194</span><span className="sign">+</span></h2>
                                    <p>Worldwide Landmark</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            {/* <!--====== End Counter section ======--> */}
            {/* <!--====== Start CTA Section ======--> */}
            <section className="cta-area-v4 bg_cover parallax-bg pt-110 pb-70">
                <div className="container">
                    {Object.values(section3data).map((quotes, index) =>
                        <div className="row align-items-center" key={index}>
                            <div className="col-lg-8">
                                <div className="section-title section-title-white mb-50">
                                    <span className="span">{quotes.MainSubHeading}</span>
                                    {/* <span className="span">Request A Quote</span> */}
                                    <h2>{quotes.MainHeading}</h2>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="button mb-50">
                                    <Link to="/contact-us" className="main-btn active-btn">{quotes.Button1Title}</Link>
                                    <Link to="/about" className="main-btn"> <i className="fas fa-play"></i>{quotes.Button2Title}</Link>
                                    {/* <a href="contact.html" className="main-btn active-btn" style={{ backgroundColor: "black" }}>Get Free Quote</a>
                                <a href="about-us.html" className="main-btn"><i className="fas fa-play"></i>Tour Video</a> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="section-title section-title-white mb-50">
                                <span className="span">{section3data.MainSubHeading}</span>
                                <span className="span">Request A Quote</span>
                                <h2>{section3data.MainHeading}</h2>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="button mb-50">
                                <Link to="/contact-us" className="main-btn active-btn">{section3data.Button1Title}</Link>
                                <Link to="/about-us" className="main-btn"> <i className="fas fa-play"></i>{section3data.Button2Title}</Link>
                                <a href="contact.html" className="main-btn active-btn" style={{ backgroundColor: "black" }}>Get Free Quote</a>
                                <a href="about-us.html" className="main-btn"><i className="fas fa-play"></i>Tour Video</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!--====== End CTA Section ======--> */}
            {/* <!--====== Start Features Section ======--> */}
            {/*<section className="features-area-v3">
                {about2.map((about) =>
                    <div className="features-wrpper d-flex flex-wrap">
                        <div className="features-bg bg_cover" style={{ backgroundImage: "url(" + consturlimg + about.Image + ")", backgroundPosition: "centre centre", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                        <div className="features-content-box">
                            <div className="section-title mb-35 wow fadeInUp">
                                <span className="span">Features</span>
                                <h3>{about.MainHeading}</h3>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about.MainDescription) }}></p>
                            <div className="row  wow fadeInUp">
                                <div className="col-lg-6">
                                    <div className="box-item d-flex mb-50">
                                        <div className="icon">
                                            <img src={consturlimg + about.SubImage1} alt="" />
                                        </div>
                                        <div className="info">
                                            <h5>{about.SkillSetText1}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="box-item d-flex mb-50">
                                        <div className="icon">
                                            <img src={consturlimg + about.SubImage2} alt="" />
                                        </div>
                                        <div className="info">
                                            <h5>{about.SkillSetText2}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/services" className="main-btn">{about.Button1Title}</Link>
                        </div>
                    </div>
                )}*/}
                {/* <div className="features-wrpper d-flex flex-wrap">
                    <div className="features-bg bg_cover" style={{ backgroundImage: "url(" + consturlimg + section4data.Image + ")", backgroundPosition: "centre centre", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className="features-bg bg_cover" style={{ background: "url(/assets/images/about/features-bg-2.jpg)", backgroundPosition: "centre centre", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className="features-content-box">
                        <div className="section-title mb-35 wow fadeInUp">
                            <span className="span">Features</span>
                            <h3>{section4data.MainHeading}</h3>
                            <h3>Faced with this challenge, the technologists asked themselves...</h3>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section4data.MainDescription) }}></p>
                        <div className="row  wow fadeInUp">
                            <div className="col-lg-6">
                                <div className="box-item d-flex mb-50">
                                    <div className="icon">
                                        <img src={consturlimg + section4data.SubImage1} alt="" />
                                        <img src="assets/images/icon/icon-5.png" alt="" />
                                    </div>
                                    <div className="info">
                                        <h5>{section4data.SkillSetText1}</h5>
                                        <h5>Interact With
                                            Good Interface</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box-item d-flex mb-50">
                                    <div className="icon">
                                        <img src={consturlimg + section4data.SubImage2} alt="" />
                                        <img src="assets/images/icon/icon-6.png" alt="" />
                                    </div>
                                    <div className="info">
                                        <h5>{section4data.SkillSetText2}</h5>
                                        <h5>Great Support
                                            & Ticket Systems</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to="/service-page" className="main-btn">{section4data.Button1Title}</Link>
                        <a href="service-1.html" className="main-btn">{section3data.Button1Title}</a>
                    </div>
                </div> 
            </section>*/}
            {/* <!--====== End Features Section ======--> */}
            {/* <!--====== Start Team Section ======--> */}
            {/* <section className="team-area-v3 light-bg pt-110 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center mb-80">
                                <span className="span">Mangement</span>
                                <h2>Our Experts</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-item mb-50 wow fadeInUp" data-wow-delay=".2s">
                                <div className="team-img">
                                    <img src="assets/images/team/team-8.jpg" alt=""/>
                                        <div className="team-overlay">
                                            <div className="team-overly-content">
                                                <h4 className="title">Miranda H. Halim</h4>
                                            </div>
                                            <div className="team-info">
                                                <h4 className="title">Miranda H. Halim</h4>
                                                <span className="position">Founder</span>
                                                <ul className="social-link">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-item mb-50 wow fadeInUp" data-wow-delay=".3s">
                                <div className="team-img">
                                    <img src="assets/images/team/team-9.jpg" alt=""/>
                                        <div className="team-overlay">
                                            <div className="team-overly-content">
                                                <h4 className="title">Hilixer D. Downtow</h4>
                                            </div>
                                            <div className="team-info">
                                                <h4 className="title">Hilixer D. Downtow</h4>
                                                <span className="position">Founder</span>
                                                <ul className="social-link">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-item mb-50 wow fadeInUp" data-wow-delay=".5s">
                                <div className="team-img">
                                    <img src="assets/images/team/team-10.jpg" alt=""/>
                                        <div className="team-overlay">
                                            <div className="team-overly-content">
                                                <h4 className="title">Jilmaoni Y. Yankee</h4>
                                            </div>
                                            <div className="team-info">
                                                <h4 className="title">Jilmaoni Y. Yankee</h4>
                                                <span className="position">Founder</span>
                                                <ul className="social-link">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-item mb-50 wow fadeInUp" data-wow-delay=".7s">
                                <div className="team-img">
                                    <img src="assets/images/team/team-11.jpg" alt=""/>
                                        <div className="team-overlay">
                                            <div className="team-overly-content">
                                                <h4 className="title">Humble D. Dawnil</h4>
                                            </div>
                                            <div className="team-info">
                                                <h4 className="title">Humble D. Dawnil</h4>
                                                <span className="position">Founder</span>
                                                <ul className="social-link">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Team Section ======--> */}
            {/* <!--====== Start Company section ======--> */}
            <section className="clients-area-v3 pt-110 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="section-title mb-65">
                                <span className="span">Clients</span>
                                <h2>We worked with brands.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="d-flex flex-wrap justify-content-evenly" style={{ padding: "10px 0 30px 0" }}>
                            {myActiveClientsData.map((client, index) =>
                                <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }} key={index}>
                                    <Link to="/about"><img src={consturlimg + client.Image} alt="" /></Link>
                                    {/* <a href="about-us.html"><img src="assets/images/clients/client-1.jpg" alt="" /></a> */}
                                </div>
                            )}
                            {/* <div class="p-2 bd-highlight" style={{ margin: "0 15px 0 15px" }}>
                                <Link to="/about-us"><img src={consturlimg + section5datac1.Image} alt="" /></Link>
                                <a href="about-us.html"><img src="assets/images/clients/client-1.jpg" alt="" /></a>
                            </div> */}
                        </div>
                        <div class="d-flex flex-wrap justify-content-evenly" style={{ padding: "10px 30px 30px 30px" }}>

                        </div>
                    </div>
                    {/* <div className="clients-row d-flex flex-wrap align-items-center">
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-1.jpg" alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-2.png" alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-3.png" alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-4.png" alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-5.png" alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-6.png" alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-7.png" alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-8.png" alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-9.jpg" alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className="clients-column">
                            <div className="clients-item text-center">
                                <div className="clients-img">
                                    <a href="about-us.html"><img src="assets/images/clients/client-5.png" alt=""/></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!--====== End Company section ======--> */}

            {/* footer in components */}

            {/* <!--====== back-to-top ======--> */}
            <a href="#" className="back-to-top" ><i className="fas fa-angle-up"></i></a>
        </>
    )
}
