import logo from './logo.svg';
import './App.css';
import { React, useState, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, Redirect } from "react-router-dom";
import { Home } from './pages/Home';
import BlogDetails from './pages/BlogDetails';
import BlogList from './pages/BlogList';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactUs from './pages/ContactUs';
import ProductPage from './pages/ProductPage';
import ServicePage from './pages/ServicePage';
import AboutUs from './pages/AboutUs';
// import ServiceDetailsIT from './pages/ServiceDetailsIT';
// import ServiceDetailsCyberSecurity from './pages/ServiceDetailsCyberSecurity';
// import ServiceDetailsOfficeCabling from './pages/SeviceDetailsOfficeCabling';
// import ServiceDetailsNetworkandData from './pages/ServiceDetailsNetworkandData';
// import ServiceDetailsEmailandWeb from './pages/SeviceDetailsEmailandWeb';
// import ServiceDetailsCloud from './pages/ServiceDetailsCloud';
// import ServiceDetails from './pages/ServiceDetails';
import { API, apiBaseURL } from './axios';
import ServiceDetailsNew from './pages/ServiceDetailsNew';
import ScrollToTop from './components/ScrollToTop';

function App() {
  //for api
  const [serviceList, setserviceList] = useState([]);
  const [serviceError, setisError] = useState("");

  useEffect(() => {
    getMyPostData();
  }, []);

  const getMyPostData = async () => {
    try {
      const myheaders = {
        headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
      };
      const mdata = { 'DetailType': 'services' };
      const res = await API.post(`details/get-content-details`, mdata, myheaders);
      // const res = await API.get(`/content/list/2`, myheaders);
      setserviceList(res.data.data);
      // console.log("detail type services");
      // console.log(serviceList);
      // console.log(res.data.data);
    } catch (error) {
      console.log(error);
      setisError(error.message);
    }
  };

  const [blogsList, setmyBlog] = useState([]);
  const [isError, setisErrorBlog] = useState("");
  // calling the function
  useEffect(() => {
    // console.log('use effect')
    getMyData();
  }, []);

  // using asynk and await
  const getMyData = async () => {
    // console.log('get my post data called');
    try {
      // console.log('get my post data called in try');
      const myheaders = {
        headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
      };
      // console.log('get my post data called in try now get data');
      const res = await API.get(`/blogs/list`, myheaders);
      // console.log(res.data.data);
      console.log(res.data.message);
      setmyBlog(res.data.data);
      console.log(blogsList);
    } catch (error) {
      // console.log('get my post data called in catch');
      console.log(error.message);
      setisErrorBlog(error.message);
    }
  };

  const myservices = Object.values(serviceList).map((service) =>
    <Route path={"/" + service.DetailSlug} element={<ServiceDetailsNew slug={service.DetailSlug} />} />
  );

  const mybogs = blogsList.map((blogs) =>
    <Route path={"/" + blogs.BlogSlug} element={<BlogDetails slug={blogs.BlogSlug} />} />
  );

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            {mybogs}
            {/* <Route path="/blog-details" element={<BlogDetails/>} /> */}
            <Route path="/blog" element={<BlogList />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/projects" element={<ProductPage />} />
            {myservices}
            {/* <Route path="/service-details-it" element={<ServiceDetailsIT />} />
            <Route path="/service-details-cyber-security" element={<ServiceDetailsCyberSecurity />} />
            <Route path="/service-details-office-cabling" element={<ServiceDetailsOfficeCabling />} />
            <Route path="/service-details-network-data" element={<ServiceDetailsNetworkandData />} />
            <Route path="/service-details-email-web" element={<ServiceDetailsEmailandWeb />} />
            <Route path="/service-details-cloud" element={<ServiceDetailsCloud />} /> */}
            <Route path="/services" element={<ServicePage />} />
            <Route path="/about" element={<AboutUs />} />
            {/* <Route path="/service-details-new" element={<ServiceDetailsNew />} /> */}
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
