import { React, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom'
import { API, apiBaseURL } from '../axios';
import swal from 'sweetalert';
import { FaTwitter } from 'react-icons/fa';

export default function Footer() {
    // const [verified, setVerified] = useState(false);
    const [ddata, setddata] = useState([]);
    // const [errMsg, setErrmsg] = useState([]);
    const [footerData, setFooterData] = useState([]);
    const [isError, setisError] = useState("");

    useEffect(() => {
        // console.log('use effect')
        getFooterData();
    }, []);

    const getFooterData = async () => {
        // console.log('get my post data called');
        try {
            // console.log('get my post data called in try');
            const headers = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // console.log('get my post data called in try now get data');
            const { data } = await API.get(`/footer-content/list`, headers);
            console.log(data.message);
            setFooterData(data.data);
            console.log(footerData);

        } catch (error) {
            // console.log('get my post data called in catch');
            console.log(error.message);
            setisError(error.message);
        }
    };

    var err = true;
    const handleSubmit = async (e) => {
        // alert('A name was submitted: ');
        e.preventDefault();
        err = false;
        try {
            // debugger;
            // console.log(ddata);
            // console.log(err);
            if (!err) {
                const myheaders = {
                    headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH', 'Content-Type': 'application/json' }
                };
                const { data } = await API.post(`subscribers/add/`, ddata, myheaders);
                console.log("data for sub");
                const res = await API.post(`/send-email/subscriber`, ddata, myheaders);
                console.log("res"+ res);
                const resp2 = await API.post(`/send-email/sthankyou`, ddata, myheaders);
                console.log("resp2"+ resp2);
                swal("", "Thank you for subscribing to our newsletter.", "success");
                // swal("", data.message, "success");
                // document.querySelector("form").reset();
                document.getElementById('SubscriberEmail').value = '';
            } else {
                console.log(err);
                swal("Nah!", "Error", "error");
            }

        }
        catch (error) {
            alert(error);
        }
    }

    const handle = (e) => {
        const newdata = { ...ddata }
        newdata[e.target.id] = e.target.value;

        setddata(newdata)
        console.log(newdata);
    }

    return (
        <>
            {/*====== Start Footer ======*/}

            <footer className="footer-area footer-area-v1">
                <div className="footer-widget blue-bg pt-90 pb-60">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-3 col-md-4 col-sm-12">
                                <h4 className="widget-title" style={{ color: "white" }}>Sevices</h4>
                                <div className="about-content" id="footer-list">
                                    <ul>
                                        {footerData && footerData.map(value => (
                                            value.DetailType == 'services' ?
                                                <>
                                                    <li><Link to={"/" + value.LinkToPage}>{value.Text}</Link></li>
                                                </>
                                                : ""
                                        )
                                        )}
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <div className="row">
                                    {/*<h4 className="widget-title" style={{ color: "white" }}>About Us</h4>
                                    <div className="about-content">
                                        {footerData && footerData.map(value => (
                                            value.DetailType == 'about-us' ?
                                                <>
                                                    <p>{value.Text}</p>
                                                </>
                                                : ""
                                        )
                                        )}
                                        {/* <p>Headquartered in New York City and with associates based in Chicago, Amvean had its genesis at the beginning of the new millennium. At that time, three forward-thinking technologists met to contemplate the advantages of unifying their particular domains of expertise to form one holistic and cross-functional IT solutions provider.

                                    </p>
                                    <ul>
                                        <li><a href="#">Our Team</a></li>
                                        <li><a href="#">Our Clients</a></li>
                                        <li><a href="#">Blog</a></li>
                                    </ul> 
                                    </div>*/}
                                </div>
                                <div className="row">
                                    <h4 className="widget-title" style={{ color: "white" }}>Social</h4>
                                    <div className="about-content" id="footer-list">
                                        <ul><li><a href="https://twitter.com/Amvean" target="_blank"><FaTwitter style={{ marginRight: "5px" }} />Follow us on Twitter</a></li></ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <h4 className="widget-title" style={{ color: "white" }}>Services</h4>
                                <div className="about-content" id="footer-list">
                                    <ul>
                                        {footerData && footerData.map(value => (
                                            value.DetailType == 'services' ?
                                                <>
                                                    <li><Link to={"/" + value.LinkToPage}>{value.Text}</Link></li>
                                                </>
                                                : ""
                                        )
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12">
                                <div className="row">
                                    <h4 className="widget-title" style={{ color: "white" }}>Contact us</h4>
                                    <div className="about-content" id="footer-list">
                                        <ul>
                                            {footerData && footerData.map(value => (
                                                value.DetailType == 'contact-us' ?
                                                    <>
                                                        <li><Link to="/contact-us">{value.Text}</Link></li>
                                                    </>
                                                    : ""
                                            )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <h4 className="widget-title" style={{ color: "white" }}>Social</h4>
                                    <div className="about-content" id="footer-list">
                                        <ul><li><a href="https://twitter.com/Amvean" target="_blank"><FaTwitter style={{ marginRight: "5px" }} />Follow us on Twitter</a></li></ul>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="widget newsletter-widget wow fadeInUp mb-30 text-start">
                                    <h4 className="widget-title">Newsletter</h4>
                                    <div className="newsletter-form">
                                        <p>Rest assured, we value your privacy and promise to never spam your inbox. Subscribe to our newsletter to stay up-to-date with the latest news and exclusive offers!</p>
                                        <form method="post" onSubmit={(e) => handleSubmit(e)}>
                                            <div className="form_group">
                                                <input type="email" className="form_control text-white" placeholder="Enter email address" id="SubscriberEmail" name="SubscriberEmail" required onChange={(e) => handle(e)} />
                                                <button className="newsletter-btn"><i className="far fa-paper-plane"></i></button>
                                            </div>
                                        </form>
                                        {/* <p>*** We never gonna spamming</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-8 col-md-6 col-sm-12">
                                Widget
                                <div className="widget about-widget wow fadeInUp mb-30 text-start">
                                    <h4 className="widget-title">About Us</h4>
                                    <div className="about-content">
                                        <p>Headquartered in New York City and with associates based in Chicago, Amvean had its genesis at the beginning of the new millennium. At that time, three forward-thinking technologists met to contemplate the advantages of unifying their particular domains of expertise to form one holistic and cross-functional IT solutions provider.

                                        </p>
                                        <ul className="social-link">
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                            <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget instagram-feed-widget wow fadeInUp mb-30">
                                    <h4 className="widget-title">Instagram Feeds</h4>
                                    <ul className="instagram-list">
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-1.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-2.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-3.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-4.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-5.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-6.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-7.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-8.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="https://webtend.net/demo/html/qempo/assets/images/footer/instagram/insta-9.jpg" alt="" />
                                                <div className="insta-overlay">
                                                    <i className="fab fa-instagram"></i>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget recent-post-widget wow fadeInUp mb-30 text-start">
                                    <h4 className="widget-title">News Feeds</h4>
                                    <ul className="recent-post-list">
                                        <li className="post-thumbnail-content">
                                            <img src="https://webtend.net/demo/html/qempo/assets/images/footer/news/thumb-1.jpg" className="img-fluid" alt="" />
                                            <div className="post-title-date">
                                                <span className="posted-on"><i className="far fa-calendar-alt"></i><a href="#">25 Mar 2021</a></span>
                                                <h6><a href="#">We can build anything ni
                                                    hill ground or building.</a></h6>
                                            </div>
                                        </li>
                                        <li className="post-thumbnail-content">
                                            <img src="https://webtend.net/demo/html/qempo/assets/images/footer/news/thumb-2.jpg" className="img-fluid" alt="" />
                                            <div className="post-title-date">
                                                <span className="posted-on"><i className="far fa-calendar-alt"></i><a href="#">25 Mar 2021</a></span>
                                                <h6><a href="#">We can build anything ni
                                                    hill ground or building.</a></h6>
                                            </div>
                                        </li>
                                        <li className="post-thumbnail-content">
                                            <img src="https://webtend.net/demo/html/qempo/assets/images/footer/news/thumb-3.jpg" className="img-fluid" alt="" />
                                            <div className="post-title-date">
                                                <span className="posted-on"><i className="far fa-calendar-alt"></i><a href="#">25 Mar 2021</a></span>
                                                <h6><a href="#">We can build anything ni
                                                    hill ground or building.</a></h6>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                Widget
                                <div className="widget newsletter-widget wow fadeInUp mb-30 text-start">
                                    <h4 className="widget-title">Newsletter</h4>
                                    <div className="newsletter-form">
                                        <p>Subscribe our newsletter to get our
                                            latest updates & news.</p>
                                        <form onSubmit="return false;">
                                            <div className="form_group">
                                                <input type="email" className="form_control text-white" placeholder="Enter email address" name="email" required />
                                                <button className="newsletter-btn"><i className="far fa-paper-plane"></i></button>
                                            </div>
                                        </form>
                                        <p>*** We never gonna spamming</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* Copyright area */}
                <div className="copyright-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="copyright-text">
                                    <p>Copyright 2023 Amvean | All Rights Reserved</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12">
                                <div className="footer-logo text-center">
                                    <Link to="/"><img src="/assets/images/logo/amvean-black.png" alt="" style={{ filter: "grayscale(.8)" }} /></Link>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 col-sm-12">
                                <div className="copyright-nav">
                                    <ul>
                                        <li><Link to="/contact-us">Get Support</Link></li>
                                        <li><Link to="/about">Policy</Link></li>
                                        <li><Link to="/contact-us">Get In Touch</Link></li>
                                        {/* <li><a href="#">Get Support</a></li> */}
                                        {/* <li><a href="#">Policy</a></li> */}
                                        {/* <li><a href="#">Get In Touch</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!--====== End Footer ======--> */}
        </>
    )
}
