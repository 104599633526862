import axios from "axios";

export const API = axios.create({
    // baseURL: "http://localhost:7000"
     baseURL: "https://www.amvean.com/"
});

export const apiBaseURL = ({
    // baseURL: "http://localhost:7000/"
    // baseURL: "http://51.79.205.255:8700/"
     baseURL: "https://www.amvean.com/"
})
