import { React, useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../components/Header';
import { API, apiBaseURL } from '../axios';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';


export const Home = () => {


    const [sliderdata, setsliderdata] = useState([]);
    const [aboutdata, setaboutdata] = useState({});
    const [servicedata, setservicedata] = useState({});
    const [projectdata, setprojectdata] = useState({});
    const [sponsordata, setsponsordata] = useState({});
    const [contactdata, setcontactdata] = useState({});
    const [blogdata, setblogdata] = useState([]);
    const [serviceError, setisError] = useState("");

    // calling the function
    useEffect(() => {
        // console.log('use effect')
        document.title = 'Amvean';
        getMyPostData();
        getMyBlogData();
    }, []);

    // using asynk and await
    const getMyPostData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // const mdata = { 'DetailType': 'services' };
            // const { data } = await axios.post(`details/get-content-details/`, mdata, config);
            // const res = await axios.get(`/content/list/2`, myheaders);
            const res = await API.get(`/content/list/1`, myheaders);
            console.log(res.data.message);
            console.log("in the home");
            // setserviceList(res.data.contentdetails.services);
            // setserviceList(data.data);
            setsliderdata(res.data.content);
            setaboutdata(res.data.contentdetails['about-us']);
            setservicedata(res.data.contentdetails.services);
            setprojectdata(res.data.contentdetails.projects);
            setsponsordata(res.data.contentdetails.sponsors);
            setcontactdata(res.data.contentdetails['contact-setting']);
            // console.log(sliderdata);
            // console.log(aboutdata);
            console.log("home-service-data");
            console.log(res.data.contentdetails.services);
            console.log(servicedata);
            // console.log(projectdata);
            // console.log(sponsordata);
            // console.log(contactdata);

        } catch (error) {
            console.log(error);
            setisError(error.message);
        }
    };

    const getMyBlogData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            const res = await API.get(`/blogs/list`, myheaders);
            console.log(res.data.message);
            console.log("in the home blog");
            setblogdata(res.data.data);
            console.log("blog data");
            console.log(blogdata);
            // console.log(blogdata.length);
        } catch (error) {
            console.log(error);
            setisError(error.message);
        }
    };

    const contactNo = Object.values(contactdata).filter(home =>
        home.Url === 'home_page_section_contact_number'
    );
    const email1 = Object.values(contactdata).filter(home =>
        home.Url === 'home_page_section_email1'
    );
    const email2 = Object.values(contactdata).filter(home =>
        home.Url === 'home_page_section_email2'
    );
    const address = Object.values(contactdata).filter(home =>
        home.Url === 'home_page_section_address'
    );

    const consturlimg = apiBaseURL.baseURL;

    const myActiveSponserData = Object.values(sponsordata).filter(sponser =>
        sponser.Status === 1
    )

    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: false,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    const options2 = {
        loop: true,
        center: true,
        items: 5,
        margin: 0,
        autoplay: true,
        dots: false,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 3
            },
            600: {
                items: 5
            },
            1000: {
                items: 5
            }
        }
    };

    const options3 = {
        loop: true,
        center: true,
        items: 3,
        autoplay: true,
        autoplayTimeout: 8500,
        dots: true,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        },
        navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>']
    };

    // const options4 = {
    //     loop: true,
    //     center: true,
    //     items: 3,
    //     margin: 0,
    //     autoplay: true,
    //     dots: true,
    //     autoplayTimeout: 8500,
    //     smartSpeed: 450,
    //     nav: false,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 2
    //         },
    //         1000: {
    //             items: 4
    //         }
    //     }
    // };

    return (
        <>
            <Header logo="colored" />
            {/* to check if api is working */}

            {/* {console.log(myData)}
            {myData.map( post => 
                (<h5>
                    {post.AdminFirstName}
                </h5>)
            )
            } */}
            {/* <ul>{name}</ul> */}

            {/*====== Start Preloader ======*/}
            {/* <div className="preloader">
                <div className="lds-ellipsis">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> */}

            {/*====== End Preloader ======*/}
            {/*====== Search From ======*/}
            <div className="modal fade" id="search-modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="form_group">
                                <input type="text" className="form_control" placeholder="Search here..." />
                                <button className="search_btn"><i className="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>{/*====== Search From ======*/}
            {/*====== offcanvas-panel ======*/}
            <div className="panel-overlay"></div>
            {/* <div className="offcanvas-panel">
                <div className="offcanvas-panel-inner">
                    <div className="panel-logo">
                        <a href="#"><img src="/assets/images/logo/logo-1.png" alt="Qempo" /></a>
                    </div>
                    <div className="about-us">
                        <h5 className="panel-widget-title">About Us</h5>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="panel-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="fal fa-map-marker-alt"></i>
                                121 King St, Melbourne VIC 3000, Australia.
                            </li>
                            <li>
                                <i className="fal fa-envelope-open"></i>
                                <a href="#"> hello@barky.com</a>
                                <a href="#">info@barky.com</a>
                            </li>
                            <li>
                                <i className="fal fa-phone"></i>
                                <a href="#">(312) 895-9800</a>
                                <a href="#">(312) 895-9888</a>
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="panel-close"><i className="fal fa-times"></i></a>
                </div>
            </div> */}

            {/*====== offcanvas-panel ======*/}
            {/*====== Start Hero Section ======*/}

            {/* header in components */}

            {/*====== End Hero Section ======*/}
            {/*====== Start Hero Section ======*/}
            <section className="hero-area-v1 aquamarine-light">
                {/* Hero Shape */}
                <div className="hero-shape">
                    <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
                    <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
                </div>
                <div className="container-fluid" id="my-container-for-slider">
                    <div className="row align-items-center" id="my-row-for-slider">
                        {/* bootstrap crousal test start */}
                        {/* <div className="col" >
                            <Carousel activeIndex={index} onSelect={handleSelect}>
                                <Carousel.Item>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-content wow fadeInDown text-start" data-wow-delay=".5s">
                                                <span className="span">Clarity + Cross-Functional Expertise = Higher Performance, Greater Value & Lower Overall Costs</span>
                                                <h1>Data Center Support</h1>
                                                <p className="mb-2">AMVEAN data center is a centralized repository for the storage, management, and dissemination of data and information organized around a particular body of knowledge or pertaining to a particular business.</p>
                                                <div className="box d-flex">
                                                    <div className="icon">
                                                        <i className="flaticon-communication"></i>
                                                    </div>
                                                    <div className="info">
                                                        <span>Get Support</span>
                                                        <h5><a href="mailto:support@amvean.com">support@amvean.com</a></h5>
                                                    </div>
                                                </div>
                                                <ul className="button">
                                                    <li><a href="/service-page" className="main-btn">Get Started Now</a></li>
                                                    <li><a href="/contact-us" className="main-btn">Learn More</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6" style={{ paddingTop: "183px" }}>
                                            <div className="hero-img wow fadeInRight" data-wow-delay=".7s">
                                                <img src="./assets/images/home/hero-img.png" alt="Hero Image" />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-content wow fadeInDown text-start" data-wow-delay=".5s">
                                                <span className="span">Clarity + Cross-Functional Expertise = Higher Performance, Greater Value & Lower Overall Costs</span>
                                                <h1>Data Center Support</h1>
                                                <p className="mb-2">AMVEAN data center is a centralized repository for the storage, management, and dissemination of data and information organized around a particular body of knowledge or pertaining to a particular business.</p>
                                                <div className="box d-flex">
                                                    <div className="icon">
                                                        <i className="flaticon-communication"></i>
                                                    </div>
                                                    <div className="info">
                                                        <span>Get Support</span>
                                                        <h5><a href="mailto:support@amvean.com">support@amvean.com</a></h5>
                                                    </div>
                                                </div>
                                                <ul className="button">
                                                    <li><a href="/service-page" className="main-btn">Get Started Now</a></li>
                                                    <li><a href="/contact-us" className="main-btn">Learn More</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6" style={{ paddingTop: "183px" }}>
                                            <div className="hero-img wow fadeInRight" data-wow-delay=".7s">
                                                <img src="./assets/images/home/hero-img.png" alt="Hero Image" />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-content wow fadeInDown text-start" data-wow-delay=".5s">
                                                <span className="span">Clarity + Cross-Functional Expertise = Higher Performance, Greater Value & Lower Overall Costs</span>
                                                <h1>Data Center Support</h1>
                                                <p className="mb-2">AMVEAN data center is a centralized repository for the storage, management, and dissemination of data and information organized around a particular body of knowledge or pertaining to a particular business.</p>
                                                <div className="box d-flex">
                                                    <div className="icon">
                                                        <i className="flaticon-communication"></i>
                                                    </div>
                                                    <div className="info">
                                                        <span>Get Support</span>
                                                        <h5><a href="mailto:support@amvean.com">support@amvean.com</a></h5>
                                                    </div>
                                                </div>
                                                <ul className="button">
                                                    <li><a href="/service-page" className="main-btn">Get Started Now</a></li>
                                                    <li><a href="/contact-us" className="main-btn">Learn More</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6" style={{ paddingTop: "183px" }}>
                                            <div className="hero-img wow fadeInRight" data-wow-delay=".7s">
                                                <img src="./assets/images/home/hero-img.png" alt="Hero Image" />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div> */}
                        {/* bootstrap crousal test end */}
                        <OwlCarousel className="owl-carousel owl-theme" key={`carousel_${sliderdata.length}`} {...options3}>
                            {sliderdata.map((slide) =>
                                <div className="item" key={slide.ContentId}>
                                    <div className="row">
                                        {/* <div className="col-lg-6">
                                            Hero content
                                            <div className="hero-content wow fadeInDown text-start" data-wow-delay=".5s">
                                                <h3>{slide.PageTitle}</h3>
                                                <div><p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slide.SliderDescription) }}></p></div>
                                                <ul className="button">
                                                    <li><a href="/service-page" className="main-btn">{slide.Button1Title}</a></li>
                                                    {slide.Button2Title ? <li><a href="/contact-us" className="main-btn">{slide.Button2Title}</a></li> : ""}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6" id="home-slider-image" >
                                            Hero Image
                                            <div className="hero-img wow fadeInRight" data-wow-delay=".7s">
                                                <img src={consturlimg + slide.SliderImage} alt="" style={{ borderRadius: "25px" }} />
                                                <img src="./assets/images/home/hero-img.png" alt="Hero Image" />
                                            </div>
                                        </div> */}
                                        <div className="col-12" id="home-slider-image-new">
                                            <div class="d-flex align-items-start justify-content-start" style={{ background: "url(" + slide.SliderImage + ")", backgroundSize: "cover", height: "540px", width: "100%" }}>
                                                <div class="article-main px-2">
                                                    <h4 class="text-white mb-2 " style={{ width: "100%" }}>{slide.PageTitle}</h4>
                                                    <div className="text-start"><p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slide.SliderDescription) }}></p></div>
                                                    <Link to="/services" className="main-btn">Know More</Link>
                                                </div>
                                                {/* <Link to="/services"><div class="article-main px-2">
                                                    <h4 class="text-white mb-2 text-center" style={{ width: "100%" }}>{slide.PageTitle}</h4>
                                                    <div className="text-center"><p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slide.SliderDescription) }}></p></div>
                                                </div></Link> */}
                                                {/* <div class="article-main px-2">
                                                    <h4 class="text-white mb-2 text-center" style={{ width: "100%" }}>{slide.PageTitle}</h4>
                                                    <div className="text-center"><p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slide.SliderDescription) }}></p></div>
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )}
                            {/* <div className="item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        Hero content
                                        <div className="hero-content wow fadeInDown text-start" data-wow-delay=".5s">
                                            <span className="span">Clarity + Cross-Functional Expertise = Higher Performance, Greater Value & Lower Overall Costs</span>
                                            <h1>Data Center Support</h1>
                                            <p className="mb-2">AMVEAN data center is a centralized repository for the storage, management, and dissemination of data and information organized around a particular body of knowledge or pertaining to a particular business.</p>
                                            <div className="box d-flex">
                                                <div className="icon">
                                                    <i className="flaticon-communication"></i>
                                                </div>
                                                <div className="info">
                                                    <span>Get Support</span>
                                                    <h5><a href="mailto:support@amvean.com">support@amvean.com</a></h5>
                                                </div>
                                            </div>
                                            <ul className="button">
                                                <li><a href="/service-page" className="main-btn">Get Started Now</a></li>
                                                <li><a href="/contact-us" className="main-btn">Learn More</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6" style={{ paddingTop: "183px" }}>
                                        Hero Image
                                        <div className="hero-img wow fadeInRight" data-wow-delay=".7s">
                                            <img src="./assets/images/home/hero-img.png" alt="Hero Image" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </OwlCarousel>
                        {/* <div className="col-lg-6">
                            Hero content
                            <div className="hero-content wow fadeInDown text-start" data-wow-delay=".5s">
                                <span className="span">Clarity + Cross-Functional Expertise = Higher Performance, Greater Value & Lower Overall Costs</span>
                                <h1>Data Center Support</h1>
                                <p className="mb-2">AMVEAN data center is a centralized repository for the storage, management, and dissemination of data and information organized around a particular body of knowledge or pertaining to a particular business.</p>
                                <div className="box d-flex">
                                    <div className="icon">
                                        <i className="flaticon-communication"></i>
                                    </div>
                                    <div className="info">
                                        <span>Get Support</span>
                                        <h5><a href="mailto:support@amvean.com">support@amvean.com</a></h5>
                                    </div>
                                </div>
                                <ul className="button">
                                    <li><a href="/service-page" className="main-btn">Get Started Now</a></li>
                                    <li><a href="/contact-us" className="main-btn">Learn More</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            Hero Image
                            <div className="hero-img wow fadeInRight" data-wow-delay=".7s">
                                <img src="./assets/images/home/hero-img.png" alt="Hero Image" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/*====== End Hero Section ======*/}
            {/*====== Start About Section ======*/}
            <section className="about-area-v1 pt-120 pb-70">
                <div className="container">
                    {Object.values(aboutdata).map((about, index) =>
                        <div className="row align-items-center" key={index}>
                            <div className="col-lg-6">
                                {/* About Image */}
                                <div className="about-img-box mb-50">
                                    <div className="experience-box wow fadeInUp">
                                        {/* <h2>45<span className="sign">+</span><span className="text">Years Of Experience</span></h2> */}
                                    </div>
                                    <div className="about-img-one wow fadeInDown">
                                        <img src={about.Image} alt="" />
                                        {/* <img src="https://webtend.net/demo/html/qempo/assets/images/about/about-1.jpg" alt="About Image" /> */}
                                    </div>
                                    <div className="about-img-two wow fadeInUp">
                                        <img src={about.SubImage1} alt="" />
                                        {/* <img src="https://webtend.net/demo/html/qempo/assets/images/about/about-2.jpg" alt="About Image" /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/* About Content Box */}
                                <div className="about-content-box mb-50 text-start">
                                    <div className="section-title mb-3 wow fadeInDown">
                                        <span className="span">{about.MainSubHeading}</span>
                                        <h2 className="small-about">{about.MainHeading}</h2>
                                    </div>
                                    <p id="home-about-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about.MainDescription) }}></p>
                                    <div className="box d-flex wow fadeInUp">
                                        <Link to="/about"><button id="prevbtn" type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingTop: "15px", paddingBottom: "15px", marginRight: "20px", marginBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }} >
                                            Continue Reading
                                        </button></Link>
                                        {/* <div className="thumb">
                                            <img src={consturlimg + about.SubImage2} alt="" />
                                        </div>
                                        <div className="content">
                                            <h4>{about.SubHeading}</h4>
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about.SubDescription) }}></p>
                                            <Link to="/"><i className="fal fa-headphones"></i>{about.Button1Title}</Link>
                                        </div> */}
                                    </div>
                                    {/* <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(about.SpecialSectionDescription) }}></div> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="row align-items-center">
                        <div className="col-lg-6">
                            About Image
                            <div className="about-img-box mb-50">
                                <div className="experience-box wow fadeInUp">
                                    <h2>45<span className="sign">+</span><span className="text">Years Of Experience</span></h2>
                                </div>
                                <div className="about-img-one wow fadeInDown">
                                    <img src="https://webtend.net/demo/html/qempo/assets/images/about/about-1.jpg" alt="About Image" />
                                </div>
                                <div className="about-img-two wow fadeInUp">
                                    <img src="https://webtend.net/demo/html/qempo/assets/images/about/about-2.jpg" alt="About Image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            About Content Box
                            <div className="about-content-box mb-50 text-start">
                                <div className="section-title mb-3 wow fadeInDown">
                                    <span className="span">About Amvean</span>
                                    <h2 className="small-about">Gathering actionable intelligence in customized databases that drive strategic decisions</h2>
                                </div>
                                <p>
                                    Headquartered in New York City and with associates based in Chicago, Amvean had its genesis at the beginning of the new millennium. At that time, three forward-thinking technologists met to contemplate the advantages of unifying their particular domains of expertise to form one holistic and cross-functional IT solutions provider.</p>
                                <div className="box d-flex wow fadeInUp">
                                    <div className="thumb">
                                        <img src="https://webtend.net/demo/html/qempo/assets/images/about/about-3.jpg" alt="About Image" />
                                    </div>
                                    <div className="content">
                                        <h4>Amvean brings clarity to IT Infrastructure, Networking and Server Solutions</h4>
                                        <p>In social science, agency is defined as the capacity of individuals to act independently</p>
                                        <a href="#"><i className="fal fa-headphones"></i>Get Support</a>
                                    </div>
                                </div>
                                <ul className="list wow fadeInDown">
                                    <li className="check">Timebound execution of solutions</li>
                                    <li className="check">Excellent engineering team to understand your customized requirements</li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/*====== End About Section ======*/}
            {/*====== Start Service Section ======*/}
            <section className="service-area-v1 aquamarine-light py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {/* Section Title */}
                            <div className="section-title mb-65 text-center">
                                <h2>What We Do</h2>
                                <span className="span">Services</span>
                            </div>
                        </div>
                    </div>
                    <div className="row  d-flex justify-content-center">
                        {/* <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" key={`carousel_${Object.keys(servicedata).length}`} {...options4}>
                            {Object.values(servicedata).map((service, index) =>
                                <div className="item px-2" key={index}>
                                    <div className="serivce-item mb-40 wow fadeInUp" id="home-service-card">
                                        <div className="icon">
                                            <img src={service.Image ? consturlimg + service.Image : "assets/images/blog/thumb-44.jpg"} className="img-fluid" alt="" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title"><Link to={service.DetailSlug}>{service.MainHeading}</Link></h3>
                                            <div id="home-service-card-desc"><p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.MainDescription) }} ></p></div>
                                            <Link to={service.DetailSlug} className="btn-link">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </OwlCarousel> */}
                        {Object.values(servicedata).filter(active=>active.Status===1).map((service, index) =>
                            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                {/* Service Item */}
                                <div className="serivce-item mb-40 wow fadeInUp" id="home-service-card">
                                    <div className="icon">
                                        <img src={service.Image ? service.Image : "assets/images/blog/thumb-44.jpg"} className="img-fluid" alt="" />
                                        {/* <img src={consturlimg + service.Image} alt="" style={{ width: "40px", height: "44px" }} /> */}
                                        {/* <i className="flaticon-target"></i> */}
                                    </div>
                                    <div className="content">
                                        <h3 className="title"><Link to={service.DetailSlug}>{service.MainHeading}</Link></h3>
                                        {/* <h3 className="title"><a href="#">{service.MainHeading}</a></h3> */}
                                        <div id="home-service-card-desc"><p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.SubDescription) }}></p></div>
                                        <Link to={service.DetailSlug} className="btn-link">Read More</Link>
                                        {/* <a href="#" className="btn-link">{service.Button1Title}</a> */}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                            Service Item
                            <div className="serivce-item mb-40 wow fadeInUp">
                                <div className="icon">
                                    <i className="flaticon-target"></i>
                                </div>
                                <div className="content">
                                    <h3 className="title"><a href="#">Network Integration</a></h3>
                                    <p>Are you looking for an effective strategist for your network? Amvean experts blend your data, voice and video into a secure, integrated network solution...</p>
                                    <a href="#" className="btn-link">Read More</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/*====== End Service Section ======*/}
            {/*====== Start FAQ Section ======*/}
            {/* <section className="faq-area-v1 pt-120 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="faq-wrapper faq-wrapper-one mb-50 text-start">
                                <div className="section-title mb-60">
                                    <span className="span">Get Answers</span>
                                    <h2>Get Every Answers</h2>
                                </div>
                                <div className="accordion" id="accordionOne">
                                    <div className="accordion-item mb-20">
                                        <a href="#" className="accordion-title" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"><span className="toggle"></span>
                                            Can i use this product before purchase?
                                        </a>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                            <div className="accordion-body">
                                                <p>We build, web-based reporting dashboards that act as a single client
                                                    portal from which all of your digital marketing analytics are reported,
                                                    giving you 24/7 access to campaign performance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item mb-20">
                                        <a href="#" className="accordion-title" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"><span className="toggle"></span>
                                            What is your marketing campaign execution process?
                                        </a>
                                        <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#accordionOne">
                                            <div className="accordion-body">
                                                <p>We build, web-based reporting dashboards that act as a single client
                                                    portal from which all of your digital marketing analytics are reported,
                                                    giving you 24/7 access to campaign performance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item mb-20">
                                        <a href="#" className="accordion-title" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"><span className="toggle"></span>
                                            What does your new client onboarding process consist?
                                        </a>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                            <div className="accordion-body">
                                                <p>We build, web-based reporting dashboards that act as a single client
                                                    portal from which all of your digital marketing analytics are reported,
                                                    giving you 24/7 access to campaign performance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item mb-20">
                                        <a href="#" className="accordion-title" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"><span className="toggle"></span>
                                            What kind of reports will I receive?
                                        </a>
                                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                            <div className="accordion-body">
                                                <p>We build, web-based reporting dashboards that act as a single client
                                                    portal from which all of your digital marketing analytics are reported,
                                                    giving you 24/7 access to campaign performance.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-img mb-50">
                                <img src="https://webtend.net/demo/html/qempo/assets/images/faq/faq-1.jpg" alt="Faq" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/*====== End FAQ Section ======*/}
            {/*====== Start Portfolio Section ======*/}


            <section id="testimonial" className="testimonials pt-70 pb-70 bg-light-sky">
                <div className="container-fluid mt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {/* Section Title */}
                                <div className="section-title text-center mb-65">
                                    <h2>Projects</h2>
                                    <span className="span">Portfolio</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" key={`carousel_${Object.keys(projectdata).length}`} {...options}>

                                {Object.values(projectdata).filter(active=>active.Status===1).map((project, index) =>
                                    <div className="portfolio-item item px-2" key={index}>
                                        <div className="portfolio-img port">
                                            <Link to="/projects" class="img-popup" style={{ width: "100%" }}><div
                                                class="d-flex align-items-end justify-content-start" style={{ background: "linear-gradient(to bottom, transparent, rgba(254,102,80,.9)), url(" + project.Image + ")", backgroundSize: "cover", height: "300px", width: "100%" }}>
                                                <div class="article-main px-2">
                                                    <h4 class="text-white mb-2 text-start" style={{ width: "90%" }}>{project.DetailHeading}</h4>
                                                </div>
                                            </div></Link>
                                            {/* <a class="img-popup"
                                                href="#" style={{ width: "100%" }}>
                                                <div
                                                    class="d-flex align-items-end justify-content-start" style={{ background: "linear-gradient(to bottom, transparent, rgba(254,102,80,.9)), url(/assets/images/portfolio/p-2.jpg)", backgroundSize: "cover", height: "300px", width: "100%" }}>
                                                    <div class="article-main px-2">
                                                        <h4 class="text-white mb-2 text-start">{project.DetailHeading}</h4>
                                                    </div>
                                                </div>
                                            </a> */}
                                        </div>
                                    </div>
                                )}
                                {/* <div className="portfolio-item item px-2">
                                    <div className="portfolio-img port">
                                        <a class="img-popup"
                                            href="#" style={{ width: "100%" }}>
                                            <div
                                                class="d-flex align-items-end justify-content-start" style={{ background: "linear-gradient(to bottom, transparent, rgba(254,102,80,.9)), url(/assets/images/portfolio/p-2.jpg)", backgroundSize: "cover", height: "300px", width: "100%" }}>
                                                <div class="article-main px-2">
                                                    <h4 class="text-white mb-2 text-start">ICICI Bank Secure <br />Access Points</h4>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div> */}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>


            {/*====== End Portfolio Section ======*/}
            {/*====== Start Company section ======*/}
            <section className="clients-area-v1 py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            {/* Section Title */}
                            <div className="section-title">
                                <span className="span">10+ <br />
                                    Happy Sponsors</span>
                            </div>
                        </div>
                        <div className="col-lg-10">

                            <div className="row">
                                <div className="col-md-12">
                                    <OwlCarousel id="customer-testimonoals2" className="owl-carousel owl-theme" key={`carousel_${myActiveSponserData.length}`} {...options2}>

                                        {myActiveSponserData.map((sponser, index) =>
                                            <div className="portfolio-item clients-logo item px-2" key={index}>
                                                <div className="portfolio-img">
                                                    <Link to="/" className="img-popup"><img src={sponser.Image} alt="" /></Link>
                                                    {/* <a href="#" className="img-popup"><img src={consturlimg + sponser.Image} alt="" /></a> */}
                                                </div>
                                            </div>
                                        )}
                                        {/* <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-1.jpg" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-2.png" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-3.png" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-4.png" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-5.png" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-6.png" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-7.png" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-8.png" alt="portfolio" /></a>
                                            </div>
                                        </div>
                                        <div className="portfolio-item clients-logo item px-2">
                                            <div className="portfolio-img">
                                                <a href="#" className="img-popup"><img src="assets/images/clients/client-9.jpg" alt="portfolio" /></a>
                                            </div>
                                        </div> */}
                                    </OwlCarousel>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            {/*====== End Company section ======*/}

            {/*====== Start contact-information-area ======*/}
            <section className="contact-info-area-v2 pt-75 pb-75">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            {/* Section Title */}
                            <div className="section-title section-title-white wow fadeInUp">
                                <h2>Get In Touch</h2>
                                <span className="span">Contact Us</span>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="info-wrapper wow fadeInDown">
                                <div className="row">
                                    <div className="col-lg-4">
                                        {/* Contact Info Item */}
                                        <div className="contact-info-item">
                                            <div className="info-inner-item text-center">
                                                <div className="icon">
                                                    <i className="flaticon-call"></i>
                                                </div>
                                                <div className="info">
                                                    {contactNo.map((number) =>
                                                        <p><a href={"tel:" + number.Detail}>{number.Detail}</a></p>
                                                    )}
                                                    {/* <p><a href="tel:212.810.2074">+1.212.810.2074</a></p> */}
                                                    {/* <p><a href="tel:980098987984">098 (908) 980 675</a></p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        {/* Contact Info Item */}
                                        <div className="contact-info-item text-center">
                                            <div className="info-inner-item">
                                                <div className="icon">
                                                    <i className="flaticon-open"></i>
                                                </div>
                                                <div className="info">
                                                    {email1.map((email) =>
                                                        <p><a href={"mailto:" + email.Detail}>{email.Detail}</a></p>
                                                    )}
                                                    {email2.map((email) =>
                                                        <p><a href={"mailto:" + email.Detail}>{email.Detail}</a></p>
                                                    )}
                                                    {/* <p><a href="mailto:sales@amvean.com">sales@amvean.com</a></p> */}
                                                    {/* <p><a href="mailto:support@amvean.com">support@amvean.com</a></p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        {/* Contact Info Item */}
                                        <div className="contact-info-item text-center">
                                            <div className="info-inner-item">
                                                <div className="icon">
                                                    <i className="flaticon-location-pin"></i>
                                                </div>
                                                <div className="info">
                                                    {address.map((address) =>
                                                        <p>{address.Detail}</p>
                                                    )}
                                                    {/* <p>134 E 28th St, New York, NY 10016, United States</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*====== Start contact-information-area ======*/}
            {/*====== Start Testmonial Section ======*/}

            {/*====== End Testmonial Section ======*/}
            {/*====== Start Blog Section ======*/}
            <section className="blog-grid-one pt-120 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            {/* Blog Content Box */}
                            <div className="blog-content-box mb-40 wow fadeInUp text-start" data-wow-delay=".2s">
                                <div className="section-title mb-20">
                                    <h2>Our Blogs</h2>
                                    <span className="span">Blog Insights</span>
                                </div>
                                <p>The definition of an agency is a group
                                    of people that performs some specific
                                    task, or that helps others.</p>
                                <Link to="/blog" className="main-btn">News Feeds</Link>
                                {/* <a href="#" className="main-btn">News Feeds</a> */}
                            </div>
                        </div>
                        {blogdata.slice(0, 3).map((blog) =>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                {/* Blog Post Item */}
                                <div className="blog-post-item mb-40 wow fadeInDown" data-wow-delay=".3s">
                                    <div className="post-thumbnail">
                                        <img src={blog.BlogImage} alt="" />
                                        {/* <img src="https://webtend.net/demo/html/qempo/assets/images/blog/blog-1.jpg" alt="" /> */}
                                    </div>
                                    <div className="entry-content">
                                        <div className="post-meta">
                                            <ul>
                                                <li><span><i className="fal fa-calendar-alt"></i>{blog.Created.split('T')[0]}
                                                    <a className="cat">Business</a></span></li>
                                                {/* <li><span><i className="fal fa-calendar-alt"></i><Link to="/">{blog.Created}</Link><Link to="/" className="cat">Business</Link></span></li> */}
                                                {/* <li><span><i className="fal fa-calendar-alt"></i><a href="#">{blog.Created}</a><a href="#" className="cat">Business</a></span></li> */}
                                            </ul>
                                        </div>
                                        <h3 className="title"><Link to={"/" + blog.BlogSlug}>{blog.BlogTitle}</Link></h3>
                                        {/* <h3 className="title"><a href="#">{blog.BlogTitle}</a></h3> */}
                                        <Link to={"/" + blog.BlogSlug} className="btn-link">Read More</Link>
                                        {/* <a href="#" className="btn-link">Read More</a> */}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                            Blog Post Item
                            <div className="blog-post-item mb-40 wow fadeInDown" data-wow-delay=".3s">
                                <div className="post-thumbnail">
                                    <img src="https://webtend.net/demo/html/qempo/assets/images/blog/blog-1.jpg" alt="" />
                                </div>
                                <div className="entry-content">
                                    <div className="post-meta">
                                        <ul>
                                            <li><span><i className="fal fa-calendar-alt"></i><a href="#">18th Jan 2023</a><a href="#" className="cat">Business</a></span></li>
                                        </ul>
                                    </div>
                                    <h3 className="title"><a href="#">Network and Phone System Install</a></h3>
                                    <a href="#" className="btn-link">Read More</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/*====== End Blog Section ======*/}

            {/* footer in components */}

            {/* <!--====== back-to-top ======--> */}
            <a href="#" class="back-to-top" ><i class="fas fa-angle-up"></i></a>
        </>
    )
}