import { React, useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import { apiBaseURL } from '../axios';

export default function Blog(props) {
    const { items } = props;
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 6;

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    const consturlimg = apiBaseURL.baseURL;

    return (
        <>
            {currentItems.map((myblog) =>
                <div className="col-lg-6" key={myblog.BlogId} style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <div className="blog-post-item mb-20 wow fadeInUp">
                        <div className="post-thumbnail">
                            <img src={myblog.BlogImage} alt={myblog.BlogImage} />
                        </div>
                        <div className="entry-content">
                            <Link to={"/" + myblog.BlogSlug}><h3 className="title">{myblog.BlogTitle}</h3></Link>
                            {/* <h3 className="title"><a href="blog-details.html"></a>{blog.BlogTitle}</h3> */}
                            {/* <p className='cutoff-txt' ref={blogdesc}></p> */}
                            <p className='cutoff-txt' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(myblog.BlogSummary) }}></p>
                            {/* <p dangerouslySetInnerHTML={{ __html: blog.BlogDescription }}></p> */}
                            {/* <p className='cutoff-txt'>{blog.BlogDescription}</p> */}
                            <div>
                                {/* <button style={{ background: "white", color: "#fd6350", marginTop: "30px", marginBottom: "30px", fontSize: "20px" }}><Link to={"/blog-details-" + myblog.BlogId}>Continue Reading <i class="far fa-arrow-right"></i></Link>
                                </button> */}
                                <Link to={"/" + myblog.BlogSlug}><button id="prevbtn" type="button" className="btn btn-danger" style={{ background: "linear-gradient(90deg, #fd6151 0%, #ff774a 100%)", border: "none", borderRadius: "30px", paddingTop: "15px", paddingBottom: "15px", marginRight: "20px", marginBottom: "10px", paddingLeft: "30px", paddingRight: "30px", marginTop: "15px" }} >
                                    Continue Reading
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* <Items currentItems={currentItems} /> */}
            <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
                containerClassName='pagination'
                pageLinkClassName='pagelink'
                previousLinkClassName='prevlink'
                nextLinkClassName='nextlink'
                activeLinkClassName='activelink'
            />
        </>
    );
}