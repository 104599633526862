import { React, useState, useEffect } from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { API, apiBaseURL } from '../axios';
import DOMPurify from 'dompurify';


export default function ServicePage() {
    const [toShow, setToShow] = useState(false);

    const showMenu = () => {
        toShow === true ? setToShow(false) : setToShow(true)
    }

    // const [serviceList, setserviceList] = useState([]);
    const [section1data, setsection1] = useState([]);
    const [section2data, setsection2] = useState({});
    const [section3data, setsection3] = useState({});
    const [section4data, setsection4] = useState({});
    const [isError, setisError] = useState("");

    useEffect(() => {
        document.title = 'Amvean - Services';
        getMyPostData();
    }, []);

    const getMyPostData = async () => {
        try {
            const myheaders = {
                headers: { 'x-access-token': '@R~D_0V&4Z7IE!86NJSH' }
            };
            // const ddata = { 'DetailType': 'services' };
            // const { res } = await axios.post(`details/get-content-details/`, ddata, myheaders)
            const res = await API.get(`/content/list/2`, myheaders);
            console.log("service-page");
            console.log(res.data.content);
            // setserviceList(res.data);
            setsection1(res.data.content);
            setsection2(res.data.contentdetails.services);
            setsection3(res.data.contentdetails['request-quote']);
            setsection4(res.data.contentdetails.faqs);
            console.log("without filter");
            console.log(section2data);
        } catch (error) {
            console.log("service-page error");
            setisError(error.message);
        }
    };

    const consturlimg = apiBaseURL.baseURL;

    const myActiveData = Object.values(section2data).filter(service =>
        service.Status === 1
    )
    console.log("with filter");
    console.log(myActiveData);

    return (
        <>
            <Header logo="white" />
            {/* <!--====== Start Preloader ======--> */}
            {/* <div className="preloader">
                <div className="lds-ellipsis">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> */}
            {/* <!--====== End Preloader ======--> */}
            {/* <!--====== offcanvas-panel ======--> */}
            <div className="panel-overlay"></div>
            <div className="offcanvas-panel">
                <div className="offcanvas-panel-inner">
                    <div className="panel-logo">
                        <a href="index.html"><img src="assets/images/logo/logo-1.png" alt="Qempo" /></a>
                    </div>
                    <div className="about-us">
                        <h5 className="panel-widget-title">About Us</h5>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsam alias quae cupiditate quas, neque eum magni impedit asperiores ad id sint repudiandae quaerat, omnis commodi consequatur dolore rerum deleniti!
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="panel-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="fal fa-map-marker-alt"></i>
                                121 King St, Melbourne VIC 3000, Australia.
                            </li>
                            <li>
                                <i className="fal fa-envelope-open"></i>
                                <a href="mailto:hello@barky.com"> hello@barky.com</a>
                                <a href="mailto:info@barky.com">info@barky.com</a>
                            </li>
                            <li>
                                <i className="fal fa-phone"></i>
                                <a href="tel:(312)-895-9800">(312) 895-9800</a>
                                <a href="tel:(312)-895-9888">(312) 895-9888</a>
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="panel-close"><i className="fal fa-times"></i></a>
                </div>
            </div>
            {/* <!--====== offcanvas-panel ======--> */}

            {/* header in components */}

            {/* <!--====== Start Bredcumbs section ======--> */}
            <div className="new-menu-bar" style={{position: "relative"}}>
                <div className="hero-shape">
                    <div className="shape shape-one wow slideInLeft" data-wow-delay=".5s"></div>
                    <div className="shape shape-two wow slideInRight" data-wow-delay="1s"></div>
                </div>
                <div className="header-bg-img" style={{ paddingTop: "100px", backgroundColor: "#DBF3F3" }}>
                    {section1data.map((jumbo) =>
                        <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/service-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="page-title-inner">
                                            <ul className="bredcumbs-link">
                                                <li><Link to="/">{jumbo.JumbotronNavLink1Title}</Link></li>
                                                <li className="active">{jumbo.JumbotronNavLink2Title}</li>
                                            </ul>
                                            <div className="title">
                                                <h1>{jumbo.JumbotronNavLink2Title}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
            {/* {section1data.map((jumbo) =>
                // <section className="bredcumbs-area bg_cover" style={{ background: "url(" + consturlimg + jumbo.JumbotronImage + ")", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <section className="bredcumbs-area bg_cover" style={{ background: "url(assets/images/bg/service-img.jpg)", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="page-title-inner">
                                    <ul className="bredcumbs-link">
                                        <li><Link to="/">{jumbo.JumbotronNavLink1Title}</Link></li>
                                        <li><a href="index.html">Home</a></li>
                                        <li className="active">{jumbo.JumbotronNavLink2Title}</li>
                                    </ul>
                                    <div className="title">
                                        <h1>{jumbo.JumbotronNavLink2Title}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )} */}

            {/* <section className="bredcumbs-area bg_cover" style={{ background: "url(" + consturlimg + section1data.JumbotronImage + ")", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="page-title-inner">
                                <ul className="bredcumbs-link">
                                    <li><Link to="/">{section1data.JumbotronNavLink1Title}</Link></li>
                                    <li><a href="index.html">Home</a></li>
                                    <li className="active">{section1data.JumbotronNavLink2Title}</li>
                                </ul>
                                <div className="title">
                                    <h1>{section1data.JumbotronNavLink2Title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--====== End Bredcumbs section ======--> */}
            {/* <!--====== Start Service Section ======--> */}
            <section className="service-area-v4 pt-120">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        {myActiveData.map((cards, index) =>
                            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                <div className="service-item mb-55 wow fadeInUp" style={{ boxShadow: "0px 16px 32px 1px rgba(0, 0, 0, 0.1)", borderRadius: "15px", height: "90%", padding: "25px 25px 25px 25px" }}>
                                    <div className="icon">
                                        <img src={cards.Image ? cards.Image : "assets/images/blog/thumb-44.jpg"} alt="" />
                                        {/* <img src={consturlimg + cards.Image} alt="" style={{ height: "80px", width: "80px" }} /> */}
                                        {/* <i className="flaticon-target"></i> */}
                                    </div>
                                    <div className="content text-center">
                                        <h3 className="title"><Link to={"/" + cards.DetailSlug}>{cards.MainHeading}</Link>
                                            <a href="service-details.html">{cards.DetailHeading}</a>
                                        </h3>
                                        <p>{cards.Detail}</p>
                                        {/* <ul className="list">
                                            <li className="check">Mobile & Web Application Design</li>
                                            <li className="check">Software Development</li>
                                            <li className="check">Game Design & Development</li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* {serviceList && serviceList.map(value => (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="service-item mb-55 wow fadeInUp" style={{ boxShadow: "0px 16px 32px 5px rgba(0, 0, 0, 0.1)", height: "95%", width: "95%", padding: "25px 25px 25px 25px" }}>
                                    <div className="icon">
                                        <img src={consturlimg + value.Image} alt="" />
                                        <i className="flaticon-target"></i>
                                    </div>
                                    <div className="content">
                                        <h3 className="title"><Link to={"/service-details-" + value.ContentDetailId}>{value.MainHeading}</Link>
                                            <a href="service-details.html">{value.DetailHeading}</a>
                                        </h3>
                                        <p>{value.Detail}</p>
                                        <ul className="list">
                                            <li className="check">Mobile & Web Application Design</li>
                                            <li className="check">Software Development</li>
                                            <li className="check">Game Design & Development</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                        )} */}
                    </div>
                </div>
            </section>
            {/* <!--====== End Service Section ======--> */}
            {/* <!--====== Start CTA Section ======--> */}
            <section className="cta-area-v4 bg_cover parallax-bg pt-110 pb-70">
                <div className="container">
                    {Object.values(section3data).map((quotes, index) =>
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="section-title section-title-white mb-50">
                                    <span className="span">{quotes.MainSubHeading}</span>
                                    <h2>{quotes.MainHeading}</h2>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="button mb-50">
                                    <Link to="/contact-us" className="main-btn active-btn">{quotes.Button1Title}</Link>
                                    <Link to="/about" className="main-btn"> <i className="fas fa-play"></i>{quotes.Button2Title}</Link>
                                    {/* <a href="contact.html" className="main-btn active-btn" style={{backgroundColor:"black"}}>Get Free Quote</a>
                                <a href="about-us.html" className="main-btn"><i className="fas fa-play"></i>Tour Video</a> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="section-title section-title-white mb-50">
                                <span className="span">Request A Quote</span>
                                <h2>Let’s Get To Work Together
                                    Ready To Work With us.</h2>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="button mb-50">
                                <Link to="/contact-us" className="main-btn active-btn">Get Free Quote</Link>
                                <Link to="/about-us" className="main-btn"> <i className="fas fa-play"></i>Tour Video</Link>
                                <a href="contact.html" className="main-btn active-btn" style={{backgroundColor:"black"}}>Get Free Quote</a>
                                <a href="about-us.html" className="main-btn"><i className="fas fa-play"></i>Tour Video</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* <!--====== End CTA Section ======--> */}
            {/* <!--====== Start Faq section ======--> */}
            <section className="faq-area-v1 pt-120 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="faq-wrapper faq-wrapper-one mb-50">
                                <div className="section-title mb-60">
                                    <span className="span">Get Answers</span>
                                    <h3>Get Every Answers</h3>
                                </div>
                                <div className="accordion" id="accordionOne">
                                    {Object.values(section4data).map((faq, index) =>
                                        <div className="accordion-item mb-20" key={index}>
                                            <Link to="/" className="accordion-title" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" onClick={() => showMenu()}><span className="toggle"></span >{faq.DetailHeading}</Link>
                                            {/* <a href="#" className="accordion-title" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"><span className="toggle"></span >{faq.DetailHeading}</a> */}
                                            <div id="collapseOne" className={`accordion-collapse collapse ${toShow === true ? "show" : ""}`} data-bs-parent="#accordionOne">
                                                <div className="accordion-body">
                                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.Detail) }}></p>
                                                </div>
                                            </div>
                                            {/* <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                                <div className="accordion-body">
                                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.Detail) }}></p>
                                                    test1
                                                </div>
                                            </div> */}
                                        </div>
                                    )}
                                    {/* <div className="accordion-item mb-20">
                                        <a href="#" className="accordion-title" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"><span className="toggle"></span >
                                            Can i use this product before purchase?
                                        </a>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionOne">
                                            <div className="accordion-body">
                                                <p>We build, web-based reporting dashboards that act as a single client
                                                    portal from which all of your digital marketing analytics are reported,
                                                    giving you 24/7 access to campaign performance.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-img mb-50 wow fadeInRight">
                                <img src="assets/images/service/faq-2.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== End Faq section ======--> */}
            {/* <!--====== Start Footer ======--> */}
            {/* footer in components */}

            {/* <!--====== back-to-top ======--> */}
            <a href="#" className="back-to-top" ><i className="fas fa-angle-up"></i></a>
        </>
    )
}
